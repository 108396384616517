

















































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'

import { PropsData } from './UiHeader.types'
import UiHeaderSearch from './UiHeaderSearch/UiHeaderSearch.vue'
import SvgArrow from 'mtd-ui/src/icons/arrow.svg'
import SvgArrow2 from 'mtd-ui/src/icons/arrow-2.svg'
import SvgFlag from 'mtd-ui/src/icons/flag.svg'
import SvgExternalLink from 'mtd-ui/src/icons/external-link.svg'
import SvgSearch from 'mtd-ui/src/icons/search.svg'

@Component({
  components: {
    UiHeaderSearch,
    SvgArrow,
    SvgArrow2,
    SvgFlag,
    SvgExternalLink,
    SvgSearch
  }
})
export default class UiHeader extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  lastScrollPosition = 0
  isHeaderSlim = false
  isSearchVisible = false
  wasSearchOpenedAtLeastOnce = false
  // ↓ properties related to desktop header
  isDesktopDropdownVisible = false
  desktopDropdownShift = 0
  isDesktopOverlayVisible = false
  isDesktopOverlayLanguagesActive = false
  currentDesktopSubmenuIndex: number | null = null
  currentlyDesktopMenuHoveredIndex: number | null = null
  isSubmenuBeingOpened = false
  isLangHovered = false
  desktopMenuSwiperInstance: Swiper | undefined = undefined
  desktopMenuSwiperCurrentIndex = 0
  isDesktopMenuSwiperNavEnabled = false
  isDesktopMenuSwiperEnd = false
  isDesktopMenuSwiperBeginning = false
  // ↓ properties related to mobile header
  isMobileOverlayVisible = false

  @Ref('headerDesktopContent') headerDesktopContent!: HTMLDivElement
  @Ref('headerDesktopOverlay') headerDesktopOverlay!: HTMLDivElement
  @Ref('headerDesktopOverlayInner') headerDesktopOverlayInner!: HTMLDivElement
  @Ref('headerDesktopOverlayInnerScrollable')
  headerDesktopOverlayInnerScrollable!: HTMLDivElement
  @Ref('headerDesktopContentInner') headerDesktopContentInner!: HTMLDivElement
  @Ref('headerDesktopContentInnerMenu')
  headerDesktopContentInnerMenu!: HTMLDivElement
  @Ref('headerDesktopContentInnerMenuSwiper')
  headerDesktopContentInnerMenuSwiper!: HTMLDivElement
  @Ref('headerDesktopMenuItem') headerDesktopMenuItem!: HTMLDivElement[]

  get currentLanguageIndex(): number | null {
    for (let i = 0; i < this.propsData.languages.length; i++) {
      if (this.propsData.languages[i].isCurrent) return i
    }
    return null
  }

  isSubmenuLinkActive(link: string): boolean {
    if (typeof this.$route === 'undefined') return false

    if (this.$route.path.includes(link)) {
      return true
    } else {
      return false
    }
  }

  isSubmenuLinkExactActive(link: string): boolean {
    if (typeof this.$route === 'undefined') return false

    if (this.$route.path === link) {
      return true
    } else {
      return false
    }
  }

  scrollEvent(): void {
    const currentScrollPosition = window.pageYOffset

    if (currentScrollPosition >= 120) {
      if (currentScrollPosition < this.lastScrollPosition) {
        this.isHeaderSlim = false
      } else {
        this.isHeaderSlim = true
      }
    } else {
      this.isHeaderSlim = false
    }

    this.lastScrollPosition = window.pageYOffset
  }

  @Watch('isSearchVisible')
  @Watch('isDesktopOverlayVisible')
  onDesktopOverlayVisibilityChanged() {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth

    if (this.isDesktopOverlayVisible || this.isSearchVisible) {
      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = `${scrollbarWidth}px`
      this.headerDesktopContent.style.paddingRight = `${scrollbarWidth}px`
      this.headerDesktopOverlay.style.paddingRight = `${scrollbarWidth}px`
    } else {
      document.body.style.overflow = 'visible'
      document.body.style.paddingRight = `${scrollbarWidth}px`
      this.headerDesktopContent.style.paddingRight = `${scrollbarWidth}px`
      this.headerDesktopOverlay.style.right = `${scrollbarWidth}px`
    }
  }

  desktopMenuItemMouseoverHandler(index: number, event: any): void {
    if (
      this.currentlyDesktopMenuHoveredIndex === index ||
      this.currentDesktopSubmenuIndex === index
    )
      return

    this.currentlyDesktopMenuHoveredIndex = index
    this.isSubmenuBeingOpened = true

    const timeout = 150

    setTimeout(() => {
      if (this.currentlyDesktopMenuHoveredIndex !== index) return

      this.closeDesktopSubmenu()

      if (!this.propsData.menu[index].submenu) return

      setTimeout(() => {
        if (
          this.currentlyDesktopMenuHoveredIndex === null ||
          this.currentlyDesktopMenuHoveredIndex !== index
        )
          return

        this.toggleDesktopSubmenu('menu', index)

        // Wait a little bit longer to avoid unexpected UX behaviour
        // than the transition itself (which takes 300ms).
        setTimeout(() => {
          this.isSubmenuBeingOpened = false
        }, 750)
      }, timeout)
    }, timeout)
  }

  langMouseoverHandler(): void {
    if (this.isDesktopOverlayLanguagesActive || this.isLangHovered) return

    this.isLangHovered = true
    this.isSubmenuBeingOpened = true

    this.closeDesktopSubmenu()

    setTimeout(() => {
      if (!this.isLangHovered) return

      this.toggleDesktopSubmenu('languages')

      // Wait until opacity transition ends (300ms is specified in .headerDesktopOverlay_VISIBLE)
      setTimeout(() => {
        this.isSubmenuBeingOpened = false
      }, 300)
    }, 300)
  }

  toggleDesktopSubmenu(type: 'menu' | 'languages', index?: number): void {
    if (this.isSearchVisible) this.closeSearch()

    if (type === 'menu') {
      if (typeof index === 'undefined') return
      const hasSubmenu = this.propsData.menu[index].submenu
      const isOverlay = this.propsData.menu[index].isSubmenuInOverlay

      // Close if the overlay or dropdown is already opened
      if (hasSubmenu && isOverlay) {
        if (
          this.isDesktopOverlayVisible &&
          this.currentDesktopSubmenuIndex === index
        ) {
          this.currentDesktopSubmenuIndex = null
          this.isDesktopOverlayVisible = false
          return
        }
      } else if (hasSubmenu && !isOverlay) {
        if (
          this.isDesktopDropdownVisible &&
          this.currentDesktopSubmenuIndex === index
        ) {
          this.currentDesktopSubmenuIndex = null
          this.isDesktopDropdownVisible = false
          return
        }
      }

      if (hasSubmenu && !isOverlay) {
        this.desktopDropdownShift = Math.floor(
          this.headerDesktopMenuItem[index].getBoundingClientRect().left
        )
      }

      this.isDesktopOverlayLanguagesActive = false
      this.currentDesktopSubmenuIndex = index

      if (isOverlay && !this.isDesktopOverlayVisible) {
        this.isDesktopOverlayVisible = true
      } else if (!isOverlay && !this.isDesktopDropdownVisible) {
        this.isDesktopDropdownVisible = true
      }
    }

    if (type === 'languages') {
      if (this.isDesktopOverlayLanguagesActive) {
        this.isDesktopOverlayLanguagesActive = false
        this.isDesktopOverlayVisible = false
        return
      }

      this.currentDesktopSubmenuIndex = null
      this.isDesktopOverlayLanguagesActive = true

      if (!this.isDesktopOverlayVisible) {
        this.isDesktopOverlayVisible = true
      }
    }
  }

  closeDesktopSubmenu(event?: any): void {
    if (!event) {
      // opening overlay menu
      this.isDesktopOverlayLanguagesActive = false
      this.currentDesktopSubmenuIndex = null
      this.isDesktopOverlayVisible = false
      this.isDesktopDropdownVisible = false
      return
    }

    let targetHasPossibleClassName = false

    // class names of items which will close the overlay menu
    const possibleTargetClasses = [
      this.$style.headerDesktopOverlay,
      this.$style.headerDesktopOverlayInner,
      this.$style.headerDesktopContentInnerLogo,
      this.$style.headerDesktopContentInner,
      this.$style.headerDesktopContentInnerMenuNavButton,
      this.$style.headerDesktopDropdown,
    ]

    for (let i = 0; i < possibleTargetClasses.length; i++) {
      if (event.target.classList.contains(possibleTargetClasses[i])) {
        targetHasPossibleClassName = true
        break
      }
    }

    if (targetHasPossibleClassName) {
      // closing overlay menu
      this.isDesktopOverlayLanguagesActive = false
      this.currentDesktopSubmenuIndex = null
      this.isDesktopOverlayVisible = false
      this.isDesktopDropdownVisible = false
    }
  }

  closeSearch(event?: any): void {
    if (!event || event.target.classList.contains(this.$style.search)) {
      this.isSearchVisible = false
    }
  }

  toggleSearch(): void {
    if (this.isDesktopOverlayVisible || this.isDesktopDropdownVisible) {
      this.closeDesktopSubmenu()
    }
    if (this.isMobileOverlayVisible) {
      this.isMobileOverlayVisible = false
    }
    this.$nextTick(() => {
      this.isSearchVisible = !this.isSearchVisible
      this.wasSearchOpenedAtLeastOnce = true
    })
  }

  onDesktopMenuSwiperChange(): void {
    if (typeof this.desktopMenuSwiperInstance === 'undefined') return

    this.desktopMenuSwiperCurrentIndex = this.desktopMenuSwiperInstance.activeIndex

    if (this.desktopMenuSwiperInstance.isEnd) {
      this.isDesktopMenuSwiperEnd = true
    } else {
      this.isDesktopMenuSwiperEnd = false
    }

    if (this.desktopMenuSwiperInstance.isBeginning) {
      this.isDesktopMenuSwiperBeginning = true
    } else {
      this.isDesktopMenuSwiperBeginning = false
    }
  }

  desktopMenuSwiperNavHandler(direction: 'prev' | 'next'): void {
    if (typeof this.desktopMenuSwiperInstance === 'undefined') return

    if (direction === 'next') {
      this.desktopMenuSwiperInstance.slideTo(
        this.desktopMenuSwiperCurrentIndex + 1
      )
    } else if (direction === 'prev') {
      this.desktopMenuSwiperInstance.slideTo(
        this.desktopMenuSwiperCurrentIndex - 1
      )
    }

    this.onDesktopMenuSwiperChange()
  }

  initDesktopMenuSwiper(): void {
    const swiperPositioning = () => {
      if (
        typeof this.desktopMenuSwiperInstance === 'undefined' ||
        typeof this.headerDesktopContentInnerMenu === 'undefined'
      )
        return

      const containerWidth: number = Math.round(
        this.headerDesktopContentInnerMenu.getBoundingClientRect().width
      )
      const swiperWidth: number = this.desktopMenuSwiperInstance.width

      if (containerWidth === swiperWidth) {
        this.desktopMenuSwiperInstance.params.slidesOffsetBefore = 5
        this.desktopMenuSwiperInstance.params.slidesOffsetAfter = 25
        this.desktopMenuSwiperInstance.params.touchRatio = 1
        this.isDesktopMenuSwiperNavEnabled = true
      } else {
        this.desktopMenuSwiperInstance.params.slidesOffsetBefore = 0
        this.desktopMenuSwiperInstance.params.slidesOffsetAfter = 0
        this.desktopMenuSwiperInstance.params.touchRatio = 0
        this.isDesktopMenuSwiperNavEnabled = false
      }
      this.desktopMenuSwiperInstance.update()

      this.desktopMenuSwiperInstance.slideTo(0)
      this.onDesktopMenuSwiperChange()
    }

    this.desktopMenuSwiperInstance = new Swiper(
      this.headerDesktopContentInnerMenuSwiper,
      {
        slidesPerView: 'auto',
        centeredSlides: true,
        centeredSlidesBounds: true,
        freeMode: true,
        freeModeMomentum: false,
        init: false,
        on: {
          init: swiperPositioning,
          resize: swiperPositioning,
          sliderMove: this.onDesktopMenuSwiperChange
        }
      }
    )

    // @ts-ignore - due to incopletion of swiper type definition
    this.desktopMenuSwiperInstance.init()
  }

  toggleMobileOverlay(): void {
    if (this.isSearchVisible) this.closeSearch()

    if (this.isMobileOverlayVisible) {
      this.isMobileOverlayVisible = false
      document.body.style.overflow = 'visible'
    } else {
      this.isMobileOverlayVisible = true
      document.body.style.overflow = 'hidden'
    }
  }

  toggleMobileSubmenu(event: any): void {
    const parentLinkElement = event.target
    const submenuElement = event.target.nextElementSibling

    if (
      !submenuElement ||
      !submenuElement.classList.contains(
        this.$style.headerMobileOverlayInnerMenuItemSubmenu
      )
    )
      return

    if (submenuElement.offsetHeight === 0) {
      parentLinkElement.classList.add(
        this.$style.headerMobileOverlayInnerMenuItemLink_TOGGLED
      )
      submenuElement.style.height = `${submenuElement.scrollHeight}px`
    } else {
      parentLinkElement.classList.remove(
        this.$style.headerMobileOverlayInnerMenuItemLink_TOGGLED
      )
      submenuElement.style.height = 0
    }
  }

  toggleMobileLanguages(event: any): void {
    const labelElement = event.target
    const languagesElement = event.target.nextElementSibling

    if (!languagesElement) return

    if (languagesElement.offsetHeight === 0) {
      labelElement.classList.add(
        this.$style.headerMobileOverlayInnerLangLabel_TOGGLED
      )
      languagesElement.style.height = `${languagesElement.scrollHeight}px`
    } else {
      labelElement.classList.remove(
        this.$style.headerMobileOverlayInnerLangLabel_TOGGLED
      )
      languagesElement.style.height = 0
    }
  }

  mounted() {
    this.lastScrollPosition = window.pageYOffset

    this.scrollEvent()

    window.addEventListener('scroll', this.scrollEvent)

    this.initDesktopMenuSwiper()
  }
}
