









































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
const objectFitImages = require('object-fit-images')

import { PropsData } from './UiSideBySide.types'

@Component
export default class UiSideBySide extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  @Ref('image') image!: HTMLImageElement

  mounted() {
    if (this.image) objectFitImages(this.image)
  }
}
