















































import { Component, Vue, Prop } from 'vue-property-decorator'

import { PropsData } from './UiBlogPost.types'
import SvgFacebook from 'mtd-ui/src/icons/facebook.svg'
import SvgLinkedin from 'mtd-ui/src/icons/linkedin.svg'
import SvgMail from 'mtd-ui/src/icons/mail.svg'
import SvgTwitter from 'mtd-ui/src/icons/twitter.svg'

@Component({
  components: {
    SvgFacebook,
    SvgLinkedin,
    SvgMail,
    SvgTwitter
  }
})
export default class UiBlogPost extends Vue {

  fullUrl = ''

  @Prop() propsData!: PropsData

  get parsedTitle(): string {
    return `<h1 class="accent"><span>${this.propsData.title}</span></h1>`
  }
  mounted () {
    this.fullUrl = window.location.href
  }
}
