











import { Component, Vue, Prop } from 'vue-property-decorator'

import SvgArrow2 from 'mtd-ui/src/icons/arrow-2.svg'

@Component({
  components: {
    SvgArrow2
  }
})
export default class BaseLearnMore extends Vue {
  @Prop({ required: true, type: String }) learnMore!: string
}
