
















import { Component, Vue, Prop } from 'vue-property-decorator'

import SvgCheckMark from 'mtd-ui/src/icons/check-mark.svg'

@Component({
  components: {
    SvgCheckMark
  }
})
export default class BaseCheckbox extends Vue {
  @Prop({ required: true }) value!: string
  @Prop() id?: string
  @Prop() name?: string
}
