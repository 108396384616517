










import { Vue, Component } from 'vue-property-decorator'
import ErrorBoundary from '../components/utilities/ErrorBoundary'
import UiHeader from 'mtd-ui/src/components/UiHeader/UiHeader.vue'
import UiFooter from 'mtd-ui/src/components/UiFooter/UiFooter.vue'
import {
  PropsData as UiHeaderPropsData,
  MenuItem as UiHeaderMenuItem,
  SubmenuItem as UiHeaderSubmenuItem,
  Language as UiHeaderLanguage
} from 'mtd-ui/src/components/UiHeader/UiHeader.types'
import {
  PropsData as UiFooterPropsData,
  MenuItem as UiFooterMenuItem,
  BrandItem as UiFooterBrandItem,
  SocialIcon as UiFooterSocialIcon
} from 'mtd-ui/src/components/UiFooter/UiFooter.types'
import {
  MainMenu,
  SitesMenu,
  SocialMenu,
  FooterMenu,
  FooterContactData,
  BrandsLinks,
  Configuration
} from '../store/initialData.types'

@Component({
  components: {
    UiHeader,
    UiFooter,
    ErrorBoundary
  }
})
export default class LayoutDefault extends Vue {
  get mainMenu(): MainMenu {
    return (this as any).$store.state.typo3.initial.mainMenu
  }
  get sitesMenu(): SitesMenu {
    return (this as any).$store.state.typo3.initial.sitesMenu
  }
  get socialMenu(): SocialMenu {
    return (this as any).$store.state.typo3.initial.socialMenu
  }
  get footerMenu(): FooterMenu {
    return (this as any).$store.state.typo3.initial.footerMenu
  }
  get footerContactData(): FooterContactData {
    return (this as any).$store.state.typo3.initial.footerContactData
  }
  get brandsLinks(): BrandsLinks {
    return (this as any).$store.state.typo3.initial.brandsLinks
  }
  get configuration(): Configuration {
    return (this as any).$store.state.typo3.initial.configuration
  }

  get headerPropsData(): UiHeaderPropsData {
    const menu: UiHeaderMenuItem[] = this.mainMenu?.map(menuItem => {
      let submenu: UiHeaderSubmenuItem[] | undefined

      if (menuItem.children && menuItem.children.length) {
        submenu = menuItem.children.map(child => {
          return {
            label: child.title,
            link: child.link,
            imageUrl: child.image ? child.image.publicUrl : undefined
          }
        })
      }

      return {
        label: menuItem.title,
        link: menuItem.link,
        submenu,
        isSubmenuInOverlay: menuItem.overlayMenu ? true : false
      }
    })

    const languages: UiHeaderLanguage[] = this.sitesMenu?.map(lang => {
      return {
        label: lang.title,
        link: lang.link,
        isCurrent: lang.current === 1,
        langCode: lang.countryLabel,
        flagUrl: lang.flag ? lang.flag.publicUrl : undefined
      }
    })

    let logoUrl = ''
    if (
      (this as any).$store.state.typo3.locale !==
      (this as any).$typo3.i18n.defaultLocale
    ) {
      logoUrl = `/${(this as any).$store.state.typo3.locale}`
    }

    let apiBaseUrl = (this as any).$store.$typo3.api.options.baseURL
    apiBaseUrl = apiBaseUrl.substring(0, apiBaseUrl.length - 1)

    return {
      menu,
      languages,
      logoUrl,
      apiBaseUrl,
      searchEndpoint: apiBaseUrl + this.configuration.searchPage?.url,
      i18n: {
        search: {
          label: this.configuration.translations?.search?.label,
          inputPlaceholder: this.configuration.translations?.search?.inputPlaceholder,
          noResultsFound: 'TODO: no results found for given phase',
          clearResults: this.configuration.translations?.search?.clear,
          showMore: this.configuration.translations?.search?.showMore,
          pages: this.configuration.translations?.search?.categories?.pages,
          products: this.configuration.translations?.search?.categories?.products,
          news: this.configuration.translations?.search?.categories?.news
        },
        ariaLabelRegion: this.configuration.translations?.aria?.region,
        ariaLabelSearch: this.configuration.translations?.aria?.search
      }
    }
  }

  get footerPropsData(): UiFooterPropsData {
    const menu: UiFooterMenuItem[] = this.footerMenu?.map(item => {
      return {
        label: item.title,
        link: item.link,
        target: item.target
      }
    })

    const brands: UiFooterBrandItem[] = this.brandsLinks?.map(brandsLink => {
      let imageUrl

      if (brandsLink.image) {
        imageUrl = brandsLink.image.publicUrl
      }

      return {
        title: brandsLink.title,
        link: brandsLink.link,
        imageUrl
      }
    })

    const socialIcons: UiFooterSocialIcon[] = this.socialMenu?.map(socialMenuItem => {
        let imageUrl

        if (socialMenuItem.image) {
          imageUrl = socialMenuItem.image.publicUrl
        }

        return {
          title: socialMenuItem.title,
          link: socialMenuItem.link,
          imageUrl
        }
      }
    )

    return {
      menu,
      brands,
      socialIcons,
      info: {
        imageUrl: this.footerContactData.content?.image
          ? this.footerContactData.content.image.publicUrl
          : undefined,
        header: this.footerContactData.content?.header,
        richText: this.footerContactData.content?.bodytext
      },
      textCopyright: this.configuration.translations?.general?.footer,
      i18n: {
        scrollTop: this.configuration.translations?.general?.goToTop
      }
    }
  }

  handleError (err: Error) {
    if (this.$sentry) {
      this.$sentry.captureException(err)
    }
  }
}
