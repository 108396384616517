












import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropData } from './CeMtdstorelocator_storelocations.types'
import { PropsData as UiStoreLocatorPropsData } from 'mtd-ui/src/components/UiStoreLocator/UiStoreLocator.types'

@Component({
  components: {
    UiStoreLocator: () =>
      import('mtd-ui/src/components/UiStoreLocator/UiStoreLocator.vue')
  }
})
export default class CeMtdstorelocator_storelocations extends Vue {
  @Prop() data!: PropData

  isComponentMounted = false

  get uiStoreLocatorPropsData(): UiStoreLocatorPropsData {
    const locations = this.data.points.map(point => {
      return {
        title: point.title,
        lat: point.lat,
        lng: point.lng,
        type: [...point.types],
        richText: `
					<p>
						<strong>${point.address2}</strong>
						<br/>
						${point.zip}, ${point.city}
						<br/>
						<br/>
						<strong>${this.data.i18n.ui.labels.phone}:</strong> ${point.phone}
						<br/>
						<strong>${this.data.i18n.ui.labels.email}:</strong> <a href="mailto:${point.email}">${point.email}</a>
					</p>
				`
      }
    })

    return {
      locations,
      configuration: {
        startLat: this.data.conf.lat,
        startLng: this.data.conf.lng,
        searchCountrySuffix: this.data.conf.localizeCountry,
        apiUrl: this.data.conf.apiUrl
      },
      filters: {
        isDealerEnabled: this.data.filters.dealer.available === 1,
        isServiceEnabled: this.data.filters.service.available === 1,
        isDealerPowerEnabled: this.data.filters.dealer_power.available === 1
      },
      i18n: {
        showMore: this.data.i18n.ui.btn.showMore,
        nothingToShow: this.data.i18n.ui.placeholder.nothingToShow,
        searching: this.data.i18n.ui.placeholder.searching,
        placeholder: this.data.i18n.ui.placeholder.search,
        localiseMe: this.data.i18n.ui.btn.locate,
        dealer: this.data.filters.dealer.label,
        service: this.data.filters.service.label,
        dealer_power: this.data.filters.dealer_power.label,
        resetView: this.data.i18n.ui.btn.resetView,
        back: this.data.i18n.ui.btn.back,
        goToTheNearestLocation: this.data.i18n.ui.btn.goToNearestLocation
      }
    }
  }

  mounted() {
    this.isComponentMounted = true
  }
}
