
























































































import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropData } from './CeMtdproducts_productdetailview.types'
import UiHero from 'mtd-ui/src/components/UiHero/UiHero.vue'
import UiProductDetails from 'mtd-ui/src/components/UiProductDetails/UiProductDetails.vue'
import UiTextWithVideo from 'mtd-ui/src/components/UiTextWithVideo/UiTextWithVideo.vue'
import UiProductSlider from 'mtd-ui/src/components/UiProductSlider/UiProductSlider.vue'
import UiTable from 'mtd-ui/src/components/UiTable/UiTable.vue'
import UiTitleBar from 'mtd-ui/src/components/UiTitleBar/UiTitleBar.vue'
import UiSideBySide from 'mtd-ui/src/components/UiSideBySide/UiSideBySide.vue'
import UiProductGrid from 'mtd-ui/src/components/UiProductGrid/UiProductGrid.vue'
import { PropsData as UiHeroPropsData } from 'mtd-ui/src/components/UiHero/UiHero.types'
import { PropsData as UiProductDetailsPropsData } from 'mtd-ui/src/components/UiProductDetails/UiProductDetails.types'
import { PropsData as UiTextWithVideoPropsData } from 'mtd-ui/src/components/UiTextWithVideo/UiTextWithVideo.types'
import { PropsData as UiProductSliderPropsData } from 'mtd-ui/src/components/UiProductSlider/UiProductSlider.types'
import { PropsData as UiTablePropsData } from 'mtd-ui/src/components/UiTable/UiTable.types'
import { PropsData as UiTitleBarPropsData } from 'mtd-ui/src/components/UiTitleBar/UiTitleBar.types'
import { PropsData as UiSideBySidePropsData } from 'mtd-ui/src/components/UiSideBySide/UiSideBySide.types'
import {
  PropsData as UiProductGridPropsData,
  Product as UiProductGridProduct
} from 'mtd-ui/src/components/UiProductGrid/UiProductGrid.types'
import { Breadcrumbs, Configuration } from '../../store/initialData.types'

@Component({
  components: {
    UiHero,
    UiProductDetails,
    UiTextWithVideo,
    UiProductSlider,
    UiTable,
    UiTitleBar,
    UiSideBySide,
    UiProductGrid
  }
})
export default class CeMtdproducts_productdetailview extends Vue {
  @Prop() data!: PropData

  get breadcrumbs(): Breadcrumbs {
    return (this as any).$store.state.typo3.page.page.breadcrumbs
  }

  get configuration(): Configuration {
    return (this as any).$store.state.typo3.initial.configuration
  }

  get uiHeroPropsData(): UiHeroPropsData {
    let breadcrumbs

    if (this.breadcrumbs.length) {
      breadcrumbs = this.breadcrumbs.map(page => {
        return {
          title: page.title,
          link: page.link
        }
      })
    }

    return {
      breadcrumbs
    }
  }

  get uiProductDetailsPropsData(): UiProductDetailsPropsData {
    let image

    if (this.data.product.image) {
      let imageAlt = this.data.product.image.properties.alternative

      image = {
        url: this.data.product.image.publicUrl,
        urlRetina: this.data.product.image.urlRetina,
        urlLqip: this.data.product.image.urlLqip,
        alt: imageAlt ? imageAlt : this.data.product.name,
        width: this.data.product.image.properties.dimensions.width,
        height: this.data.product.image.properties.dimensions.height
      }
    }

    let descriptionRichText
    if (this.data.product.description && this.data.product.isAccessory) {
      descriptionRichText = this.data.product.description
    }

    return {
      title: this.data.product.name,
      descriptionRichText,
      priceFormatted: this.data.product.priceFormatted,
      isPriceVisible: this.configuration.showPrices,
      image,
      features: this.data.product.attributes
        ? this.data.product.attributes.summary
        : undefined,
      categoryName: this.data.product.categoryName,
      contactUrl: this.data.contactUrl,
      i18n: {
        description: this.data.i18n.description,
        accessories: this.data.i18n.accessories,
        characteristics: this.data.i18n.characteristics,
        related: this.data.i18n.relatedProducts,
        advantages: this.data.i18n.advantages,
        contactUs: this.data.i18n.contactUs
      },
      hasDescription:
        !this.data.product.isAccessory &&
        this.data.product.description.length > 0,
      hasAccessories: this.data.accessories.length ? true : false,
      hasCharacteristics:
        this.data.product.attributes && this.data.product.attributes.all.length
          ? true
          : false,
      hasRelated: this.data.related.length ? true : false,
      hasAdvantages: this.data.product.advantages.length ? true : false
    }
  }

  get uiTitleBarPropsData_COMPATIBLE_WITH(): UiTitleBarPropsData {
    return {
      title: this.data.i18n.compatibleWith
    }
  }

  get uiProductGrid(): UiProductGridPropsData {
    let products: UiProductGridProduct[] = []

    if (this.data.product.compatibleWith) {
      products = this.data.product.compatibleWith.map(compatibleProduct => {
        let image

        if (compatibleProduct.image) {
          const imageAlt = compatibleProduct.image.properties.alternative

          image = {
            url: compatibleProduct.image.publicUrl,
            urlRetina: compatibleProduct.image.urlRetina,
            urlLqip: compatibleProduct.image.urlLqip,
            alt: imageAlt ? imageAlt : compatibleProduct.name,
            width: compatibleProduct.image.properties.dimensions.width,
            height: compatibleProduct.image.properties.dimensions.height
          }
        }
        return {
          title: compatibleProduct.name,
          richText: compatibleProduct.description,
          link: compatibleProduct.url,
          image
        }
      })
    }

    return {
      products,
      i18n: {
        learnMore: this.data.i18n.learnMore
      }
    }
  }

  get uiTextWithVideoPropsData(): UiTextWithVideoPropsData {
    let heading
    let richText

    if (!this.data.product.isAccessory) {
      heading = this.data.i18n.description
      richText = this.data.product.description
    }

    return {
      heading,
      richText,
      videoCoverText: this.data.product.name,
      youtubeId: this.data.product.video ? this.data.product.video : undefined
    }
  }

  get uiTitleBarPropsData_ACCESSORIES(): UiTitleBarPropsData {
    return {
      title: this.data.i18n.accessories
    }
  }

  get uiProductSliderPropsData_ACCESSORIES(): UiProductSliderPropsData {
    const slides = this.data.accessories.map(accesory => {
      let image

      if (accesory.image) {
        const imageAlt = accesory.image.properties.alternative

        image = {
          url: accesory.image.publicUrl,
          urlRetina: accesory.image.urlRetina,
          urlLqip: accesory.image.urlLqip,
          alt: imageAlt ? imageAlt : accesory.label,
          width: accesory.image.properties.dimensions.width,
          height: accesory.image.properties.dimensions.height
        }
      }
      return {
        title: accesory.label,
        link: accesory.url,
        image
      }
    })

    return {
      slides
    }
  }

  get uiTitleBarPropsData_ADVANTAGES(): UiTitleBarPropsData {
    return {
      title: this.data.i18n.advantages
    }
  }

  get uiSideBySidePropsData(): UiSideBySidePropsData {
    const rows = this.data.product.advantages.map(advantage => {
      let image

      if (advantage.image) {
        const imageAlt = advantage.image.properties.alternative

        image = {
          url: advantage.image.publicUrl,
          urlRetina: advantage.image.urlRetina,
          urlLqip: advantage.image.urlLqip,
          alt: imageAlt ? imageAlt : advantage.header,
          width: advantage.image.properties.dimensions.width,
          height: advantage.image.properties.dimensions.height
        }
      }

      return {
        richText: `
					<h3>${advantage.header}</h3>
					<p>${advantage.description}</p>
				`,
        image
      }
    })

    return {
      rows
    }
  }

  get uiTitleBarPropsData_TABLE(): UiTitleBarPropsData {
    return {
      title: this.data.i18n.characteristics
    }
  }

  get uiTablePropsData(): UiTablePropsData {
    if (!this.data.product.attributes) {
      return {
        rowGroups: []
      }
    }

    const rowGroups = this.data.product.attributes.all.map(attr => {
      const rows = attr.attributes.map(nestedAttr => ({
        title: nestedAttr.title,
        value: nestedAttr.value
      }))

      return {
        heading: attr.title,
        rows
      }
    })

    return {
      rowGroups
    }
  }

  get uiTitleBarPropsData_RELATED(): UiTitleBarPropsData {
    return {
      title: this.data.i18n.relatedProducts
    }
  }

  get uiProductSliderPropsData_RELATED(): UiProductSliderPropsData {
    const slides = this.data.related.map(relatedProduct => {
      let image

      if (relatedProduct.image) {
        const imageAlt = relatedProduct.image.properties.alternative

        image = {
          url: relatedProduct.image.publicUrl,
          urlRetina: relatedProduct.image.urlRetina,
          urlLqip: relatedProduct.image.urlLqip,
          alt: imageAlt ? imageAlt : relatedProduct.label,
          width: relatedProduct.image.properties.dimensions.width,
          height: relatedProduct.image.properties.dimensions.height
        }
      }
      return {
        title: relatedProduct.label,
        link: relatedProduct.url,
        image
      }
    })

    return {
      slides
    }
  }
}
