import Vue from 'vue'

import BaseCheckbox from 'mtd-ui/src/components/BaseCheckbox/BaseCheckbox.vue'
import BaseLearnMore from 'mtd-ui/src/components/BaseLearnMore/BaseLearnMore.vue'
import BaseRadio from 'mtd-ui/src/components/BaseRadio/BaseRadio.vue'
import BaseRichText from 'mtd-ui/src/components/BaseRichText/BaseRichText.vue'
import BaseSelect from 'mtd-ui/src/components/BaseSelect/BaseSelect.vue'
import BaseSpinner from 'mtd-ui/src/components/BaseSpinner/BaseSpinner.vue'

import CeMtdbase_cta from '~/components/CeMtdbase_cta/CeMtdbase_cta.vue'
import CeMtdbase_iconlinks from '~/components/CeMtdbase_iconlinks/CeMtdbase_iconlinks.vue'
import CeMtdstorelocator_storelocations from '~/components/CeMtdstorelocator_storelocations/CeMtdstorelocator_storelocations.vue'
import CeMtdbase_slider from '~/components/CeMtdbase_slider/CeMtdbase_slider.vue'
import CeMtdbase_faq from '~/components/CeMtdbase_faq/CeMtdbase_faq.vue'
import CeMtdbase_herorte from '~/components/CeMtdbase_herorte/CeMtdbase_herorte.vue'
import CeMtdbase_timeline from '~/components/CeMtdbase_timeline/CeMtdbase_timeline.vue'
import CeMtdbase_videoslider from '~/components/CeMtdbase_videoslider/CeMtdbase_videoslider.vue'
import CeNews_pi1 from '~/components/CeNews_pi1/CeNews_pi1.vue'
import CeMtdproducts_categorypage from '~/components/CeMtdproducts_categorypage/CeMtdproducts_categorypage.vue'
import CeMtdproducts_productdetailview from '~/components/CeMtdproducts_productdetailview/CeMtdproducts_productdetailview.vue'
import CeMenuSubpages from '~/components/CeMenuSubpages/CeMenuSubpages.vue'
import CeForm_formframework from '~/components/CeForm_formframework/CeForm_formframework.vue'
import CeMtdbase_contactinfo from '~/components/CeMtdbase_contactinfo/CeMtdbase_contactinfo.vue'
import CeMtdbase_advantages from '~/components/CeMtdbase_advantages/CeMtdbase_advantages.vue'
import CeImage from '~/components/CeImage/CeImage.vue'
import CeText from '~/components/CeText/CeText.vue'
import CeMtdbase_downloadfile from '~/components/CeMtdbase_downloadfile/CeMtdbase_downloadfile.vue'
import CeMtdbase_singlevideo from '~/components/CeMtdbase_singlevideo/CeMtdbase_singlevideo.vue'

const components = {
  BaseCheckbox,
  BaseLearnMore,
  BaseRadio,
  BaseRichText,
  BaseSelect,
  BaseSpinner,
  CeMtdbase_cta,
  CeMtdbase_iconlinks,
  CeMtdstorelocator_storelocations,
  CeMtdbase_slider,
  CeMtdbase_faq,
  CeMtdbase_herorte,
  CeMtdbase_timeline,
  CeMtdbase_videoslider,
  CeNews_pi1,
  CeMtdproducts_categorypage,
  CeMtdproducts_productdetailview,
  CeMenuSubpages,
  CeForm_formframework,
  CeMtdbase_contactinfo,
  CeMtdbase_advantages,
  CeImage,
  CeText,
  CeMtdbase_downloadfile,
  CeMtdbase_singlevideo
}

export default () => {
  Object.keys(components).forEach(key => {
    Vue.component(key, components[key])
  })
}
