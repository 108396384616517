// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Eufu{margin-bottom:1.5rem}@media(min-width:768px){._2Eufu{margin-bottom:4rem}}._32fIP{font-size:2rem;font-family:\"Roboto Condensed\",sans-serif;margin-top:0}@media(min-width:768px){._32fIP{font-size:2.2rem}}@media(min-width:990px){._32fIP{font-size:2.6rem}}@media(min-width:1280px){._32fIP{font-size:3rem}}._2kmGS{padding:1rem 1.5rem;background:#f5f5f5}@media(min-width:768px){._2kmGS{padding:1.5rem 2.5rem}}._2kmGS:nth-child(odd){background:#e8e8e8}@media(min-width:768px){.XqfLq{display:-webkit-box;display:-ms-flexbox;display:flex;margin-right:-4rem}.XqfLq span{margin-right:4rem}}.XqfLq span:first-child{-webkit-box-flex:1;-ms-flex:1;flex:1}.XqfLq span:last-child{font-weight:700;-webkit-box-flex:2;-ms-flex:2;flex:2}@media(min-width:990px){.XqfLq span:last-child{-webkit-box-flex:3;-ms-flex:3;flex:3}}", ""]);
// Exports
exports.locals = {
	"rowGroup": "_2Eufu",
	"heading": "_32fIP",
	"row": "_2kmGS",
	"rowInner": "XqfLq"
};
module.exports = exports;
