// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".v1DID{background:#f5f5f5;padding:1.5rem}@media(min-width:768px){.v1DID{padding:4rem}}._18xNt{margin-bottom:.5rem}@media(min-width:768px){._18xNt{margin-bottom:1.5rem}}._18xNt img{width:4rem;height:4rem}@media(min-width:768px){._18xNt img{width:5rem;height:5rem}}", ""]);
// Exports
exports.locals = {
	"container": "v1DID",
	"image": "_18xNt"
};
module.exports = exports;
