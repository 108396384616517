var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.onVisibilityChanged,
    throttle: 100,
    intersection: { threshold: 0.3 }
  }),expression:"{\n    callback: onVisibilityChanged,\n    throttle: 100,\n    intersection: { threshold: 0.3 }\n  }"}],ref:"component"},[_c('div',{ref:"swiper",staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.propsData.slides),function(slide,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('nav-link',{class:_vm.$style.slide,attrs:{"to":slide.link}},[_c('div',{class:_vm.$style.slideImage},[(slide.image)?_c('img',{staticClass:"lazyload",attrs:{"src":slide.image.urlLqip,"data-srcset":((slide.image.url) + " 1x, " + (slide.image.urlRetina) + " 2x"),"data-src":slide.image.url,"alt":slide.image.alt,"width":slide.image.width,"height":slide.image.height,"crossorigin":"anonymous"}}):_vm._e()]),_vm._v(" "),_c('h5',{class:_vm.$style.slideLabel},[_vm._v(_vm._s(slide.title))])])],1)}),0)]),_vm._v(" "),(_vm.areProgressBarAndNavigationArrowsVisible)?_c('div',{class:_vm.$style.progressBarAndNavigation},[_c('div',{class:_vm.$style.progressBar},_vm._l((new Array(_vm.snapGridsLength)),function(_,index){
  var _obj, _obj$1;
return _c('div',{key:index,class:_vm.$style.progressBarSingle},[_c('div',{class:_vm.$style.progressBarSingleSlim}),_vm._v(" "),_c('div',{class:[
            _vm.$style.progressBarSingleThick,
            ( _obj = {}, _obj[_vm.$style.progressBarSingleThick_BEING_SET] = index <= _vm.currentSnapIndex, _obj ),
            ( _obj$1 = {}, _obj$1[_vm.$style.progressBarSingleThick_ALREADY_SET] = (_vm.isProgressBarMovingToRight && index < _vm.currentSnapIndex) ||
                (!_vm.isProgressBarMovingToRight && index > _vm.currentSnapIndex + 1), _obj$1 )
          ]})])}),0),_vm._v(" "),_c('div',{class:_vm.$style.navigation},[_c('div',{class:[
          _vm.$style.navigationArrow,
          _vm.$style.leftArrow,
          ( _obj = {}, _obj[_vm.$style.navigationArrow_DISABLED] = _vm.isSwiperBeginning, _obj )
        ],attrs:{"role":"button","title":"Previous Slide"},on:{"click":function($event){return _vm.slidePrev()}}},[_c('svg-arrow-2')],1),_vm._v(" "),_c('div',{class:[
          _vm.$style.navigationArrow,
          ( _obj$1 = {}, _obj$1[_vm.$style.navigationArrow_DISABLED] = _vm.isSwiperEnd, _obj$1 )
        ],attrs:{"role":"button","title":"Next Slide"},on:{"click":function($event){return _vm.slideNext()}}},[_c('svg-arrow-2')],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }