








import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropImage, PropAppearance } from './CeMtdbase_cta.types'
import UiCallToAction from 'mtd-ui/src/components/UiCallToAction/UiCallToAction.vue'
import UiSmallGrayBox from 'mtd-ui/src/components/UiSmallGrayBox/UiSmallGrayBox.vue'
import { PropsData as UiCallToActionPropsData } from 'mtd-ui/src/components/UiCallToAction/UiCallToAction.types'
import { PropsData as UiSmallGrayBoxPropsData } from 'mtd-ui/src/components/UiSmallGrayBox/UiSmallGrayBox.types'

@Component({ components: { UiCallToAction, UiSmallGrayBox } })
export default class CeMtdbase_cta extends Vue {
  @Prop() bodytext!: string
  @Prop() image!: PropImage
  @Prop() appearance!: PropAppearance

  get uiSmallGrayBoxPropsData(): UiSmallGrayBoxPropsData {
    let imageUrl

    if (this.image) {
      imageUrl = this.image.publicUrl
    }

    return {
      richText: this.bodytext,
      imageUrl
    }
  }

  get uiCallToActionPropsData(): UiCallToActionPropsData {
    let image

    if (this.image) {
      const imageAlt = this.image.properties.alternative

      image = {
        url: this.image.publicUrl,
        alt: imageAlt
          ? imageAlt
          : this.bodytext.replace(/(<([^>]+)>)/gi, '').substring(0, 100),
        width: this.image.properties.dimensions.width,
        height: this.image.properties.dimensions.height
      }
    }

    return {
      richText: this.bodytext,
      image
    }
  }
}
