// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3BL-V{display:inline-block;position:relative}._3aH0f{width:18px;height:18px}@media(min-width:768px){._3aH0f{width:20px;height:20px}}._3aH0f div{border:2px solid #111;width:18px;height:18px}@media(min-width:768px){._3aH0f div{width:20px;height:20px}}._2M2dw{width:18px;height:18px}@media(min-width:768px){._2M2dw{width:40px;height:40px}}._2M2dw div{border:3px solid #111;width:40px;height:40px}@media(min-width:768px){._2M2dw div{border:4px solid #111;width:60px;height:60px}}._3BL-V div{-webkit-box-sizing:border-box;box-sizing:border-box;display:block;position:absolute;border-radius:50%;-webkit-animation:_3BL-V 1.2s cubic-bezier(.5,0,.5,1) infinite;animation:_3BL-V 1.2s cubic-bezier(.5,0,.5,1) infinite;border-color:#111 transparent transparent}._3BL-V div:first-child{-webkit-animation-delay:-.45s;animation-delay:-.45s}._3BL-V div:nth-child(2){-webkit-animation-delay:-.3s;animation-delay:-.3s}._3BL-V div:nth-child(3){-webkit-animation-delay:-.15s;animation-delay:-.15s}@-webkit-keyframes _3BL-V{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes _3BL-V{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}", ""]);
// Exports
exports.locals = {
	"spinner": "_3BL-V",
	"spinner_NORMAL": "_3aH0f",
	"spinner_LARGE": "_2M2dw"
};
module.exports = exports;
