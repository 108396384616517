




import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropIcons } from './CeMtdbase_iconlinks.types'
import UiTiles from 'mtd-ui/src/components/UiTiles/UiTiles.vue'
import { PropsData as UiTilesPropsData } from 'mtd-ui/src/components/UiTiles/UiTiles.types'

@Component({ components: { UiTiles } })
export default class CeMtdbase_iconlinks extends Vue {
  @Prop() icons!: PropIcons

  get uiTilesPropsData(): UiTilesPropsData {
    const tiles = [
      ...this.icons.map(icon => {
        let imageUrl

        if (icon.content.icon) {
          imageUrl = icon.content.icon.publicUrl
        }

        return {
          imageUrl,
          text: icon.content.title,
          link: icon.content.link.url,
          target: icon.content.link.target
        }
      })
    ]

    return {
      tiles
    }
  }
}
