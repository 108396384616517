




import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropItems } from './CeMtdbase_advantages.types'
import UiSideBySide from 'mtd-ui/src/components/UiSideBySide/UiSideBySide.vue'
import { PropsData as UiSideBySidePropsData } from 'mtd-ui/src/components/UiSideBySide/UiSideBySide.types'

@Component({ components: { UiSideBySide } })
export default class CeMtdbase_advantages extends Vue {
  @Prop() items!: PropItems

  get uiSideBySidePropsData(): UiSideBySidePropsData {
    const rows = this.items.map(item => {
      let image

      if (item.content.image) {
        const imageAlt = item.content.image.properties.alternative
          ? item.content.image.properties.alternative
          : item.content.header

        image = {
          url: item.content.image.publicUrl,
          urlRetina: item.content.image.urlRetina,
          urlLqip: item.content.image.urlLqip,
          alt: imageAlt,
          width: item.content.image.properties.dimensions.width,
          height: item.content.image.properties.dimensions.height
        }
      }

      return {
        richText: `
					<h3>${item.content.header}</h3>
					<p>${item.content.description}</p>
				`,
        image
      }
    })

    return {
      rows
    }
  }
}
