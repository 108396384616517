// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1DsmP{position:relative;display:inline-block}.b-oM1{opacity:0;position:absolute;width:2rem;height:2rem}._3M_M7{width:1.8rem;height:1.8rem;border:.2rem solid #111;border-radius:50%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-ms-flex-negative:0;flex-shrink:0}@media(min-width:768px){._3M_M7{width:2rem;height:2rem}}._3M_M7 div{width:.6rem;height:.6rem;background:#fff;border-radius:50%}.b-oM1:checked~._3M_M7{background:#d50c2f;border:.2rem solid #d50c2f}", ""]);
// Exports
exports.locals = {
	"radio": "_1DsmP",
	"radioOriginal": "b-oM1",
	"radioCustom": "_3M_M7"
};
module.exports = exports;
