// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1HlE9{-webkit-box-align:center;-ms-flex-align:center;align-items:center}._1HlE9,._2B2ja{display:-webkit-box;display:-ms-flexbox;display:flex}._2B2ja{height:3.5rem;padding:0 1.5rem;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}@media(min-width:768px){._2B2ja{height:4rem}}._2B2ja svg{width:1.1rem;-webkit-transition:inherit;transition:inherit}._2B2ja:hover svg{fill:#d50c2f}._3o42y svg{-webkit-transform:rotate(-90deg);transform:rotate(-90deg)}._2lNvB{margin-right:-1.5rem}._2lNvB svg{-webkit-transform:rotate(90deg);transform:rotate(90deg)}._2nyxB{pointer-events:none;opacity:.2}._3a8i5{border:.1rem solid #e8e8e8;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:3.5rem;height:3.5rem}@media(min-width:768px){._3a8i5{width:4rem;height:4rem}}._3a8i5 span{font-family:\"Roboto Condensed\",sans-serif;color:#d50c2f}._1erGW{margin-left:1rem}", ""]);
// Exports
exports.locals = {
	"pagination": "_1HlE9",
	"paginationArrow": "_2B2ja",
	"paginationArrow_PREV": "_3o42y",
	"paginationArrow_NEXT": "_2lNvB",
	"paginationArrow_DISABLED": "_2nyxB",
	"paginationCurrent": "_3a8i5",
	"paginationText": "_1erGW"
};
module.exports = exports;
