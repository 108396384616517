





















































































import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropsData } from './UiAccordion.types'
import SvgArrow2 from 'mtd-ui/src/icons/arrow-2.svg'

@Component({
  components: {
    SvgArrow2
  }
})
export default class UiAccordion extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  toggleAccordion(event: any): void {
    const nextElementSibling = event.target.nextElementSibling

    if (nextElementSibling.offsetHeight === 0) {
      event.target.classList.add(this.$style.accordionLabel_ACTIVE)
      nextElementSibling.style.height = `${nextElementSibling.scrollHeight}px`
    } else {
      event.target.classList.remove(this.$style.accordionLabel_ACTIVE)
      nextElementSibling.style.height = 0
    }
  }

  toggleNestedAccordion(event: any): void {
    const nextElementSibling = event.target.nextElementSibling
    // const parentNode = event.composedPath()[3]
    const parentNode = event.target.parentNode.parentNode.parentNode // IE11 compatibility...

    if (nextElementSibling.offsetHeight === 0) {
      event.target.classList.add(this.$style.nestedaccordionLabel_ACTIVE)
      nextElementSibling.style.height = `${nextElementSibling.scrollHeight}px`
      parentNode.style.height = `${parentNode.scrollHeight +
        nextElementSibling.scrollHeight}px`
    } else {
      event.target.classList.remove(this.$style.nestedaccordionLabel_ACTIVE)
      nextElementSibling.style.height = 0
      parentNode.style.height = `${parentNode.scrollHeight -
        nextElementSibling.scrollHeight}px`
    }
  }
}
