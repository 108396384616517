var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5, _obj$6, _obj$7, _obj$8, _obj$9, _obj$10, _obj$11, _obj$12, _obj$13, _obj$14, _obj$15, _obj$16;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{class:_vm.$style.headerSpacer}),_vm._v(" "),_c('div',{class:_vm.$style.headerDesktop},[_c('div',{ref:"headerDesktopContent",class:_vm.$style.headerDesktopContent},[_c('div',{ref:"headerDesktopContentInner",staticClass:"frame-container",class:[
          _vm.$style.headerDesktopContentInner,
          ( _obj = {}, _obj[_vm.$style.headerDesktopContentInner_SLIM] = _vm.isHeaderSlim, _obj )
        ],on:{"mouseover":function($event){return _vm.closeDesktopSubmenu($event)}}},[_c('div',{class:[
            _vm.$style.headerDesktopContentInnerLogo,

            ( _obj$1 = {}, _obj$1[_vm.$style.headerDesktopContentInnerLogo_SLIM] = _vm.isHeaderSlim, _obj$1 )
          ],on:{"click":function () {
              if (_vm.isSearchVisible) { _vm.closeSearch() }
            }}},[_c('nav-link',{attrs:{"to":_vm.propsData.logoUrl}},[_c('img',{class:_vm.$style.headerDesktopContentInnerLogoImg,attrs:{"src":"/logo.svg","alt":"MTD Logo"}})])],1),_vm._v(" "),_c('div',{ref:"headerDesktopContentInnerMenu",class:_vm.$style.headerDesktopContentInnerMenu},[_c('div',{ref:"headerDesktopContentInnerMenuSwiper",staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.propsData.menu),function(menuItem,index){
            var _obj;
return _c('div',{key:index,ref:"headerDesktopMenuItem",refInFor:true,staticClass:"swiper-slide",class:[_vm.$style.headerDesktopContentInnerMenuItem],on:{"mouseover":function($event){return _vm.desktopMenuItemMouseoverHandler(index, $event)},"mouseleave":function () {
                    _vm.currentlyDesktopMenuHoveredIndex = null
                  },"click":function () {
                    if (menuItem.submenu && !_vm.isSubmenuBeingOpened)
                      { _vm.toggleDesktopSubmenu('menu', index) }
                    if (_vm.isSearchVisible) { _vm.closeSearch() }
                  }}},[_c('nav-link',{class:[
                    _vm.$style.headerDesktopContentInnerMenuItemInner,
                    ( _obj = {}, _obj[_vm.$style.headerDesktopContentInnerMenuItemInner_OVERLAY_OPENED] = index === _vm.currentDesktopSubmenuIndex, _obj )
                  ],attrs:{"to":menuItem.link,"event":menuItem.submenu && menuItem.submenu.length ? '' : 'click'}},[_c('span',[_vm._v("\n                    "+_vm._s(menuItem.label)+"\n                  ")]),_vm._v(" "),(menuItem.submenu)?_c('svg-arrow'):_vm._e()],1)],1)}),0)]),_vm._v(" "),_c('div',{class:[
              _vm.$style.headerDesktopContentInnerMenuNav,
              _vm.$style.headerDesktopContentInnerMenuNav_LEFT,
              ( _obj$2 = {}, _obj$2[_vm.$style.headerDesktopContentInnerMenuNav_VISIBLE] = _vm.isDesktopMenuSwiperNavEnabled &&
                  !_vm.isDesktopMenuSwiperBeginning, _obj$2 )
            ]},[_c('div',{class:_vm.$style.headerDesktopContentInnerMenuNavButton,attrs:{"role":"button"},on:{"click":function($event){return _vm.desktopMenuSwiperNavHandler('prev')}}},[_c('svg-arrow-2')],1)]),_vm._v(" "),_c('div',{class:[
              _vm.$style.headerDesktopContentInnerMenuNav,
              _vm.$style.headerDesktopContentInnerMenuNav_RIGHT,
              ( _obj$3 = {}, _obj$3[_vm.$style.headerDesktopContentInnerMenuNav_VISIBLE] = _vm.isDesktopMenuSwiperNavEnabled && !_vm.isDesktopMenuSwiperEnd, _obj$3 )
            ]},[_c('div',{class:_vm.$style.headerDesktopContentInnerMenuNavButton,attrs:{"role":"button"},on:{"click":function($event){return _vm.desktopMenuSwiperNavHandler('next')}}},[_c('svg-arrow-2')],1)])]),_vm._v(" "),_c('div',{class:_vm.$style.headerDesktopContentInnerSearchAndLang},[_c('div',{class:[
              _vm.$style.headerDesktopContentInnerSearch,
              ( _obj$4 = {}, _obj$4[_vm.$style.headerDesktopContentInnerSearch_ACTIVE] = _vm.isSearchVisible, _obj$4 )
            ],attrs:{"role":"button","aria-label":_vm.propsData.i18n.ariaLabelSearch},on:{"click":function($event){return _vm.toggleSearch()}}},[_c('svg-search')],1),_vm._v(" "),_c('div',{class:[
              _vm.$style.headerDesktopContentInnerLanguage,
              ( _obj$5 = {}, _obj$5[_vm.$style.headerDesktopContentInnerLanguage_ACTIVE] = _vm.isDesktopOverlayLanguagesActive, _obj$5 )
            ],attrs:{"role":"button","aria-label":_vm.propsData.i18n.ariaLabelRegion},on:{"mouseover":function($event){return _vm.langMouseoverHandler()},"mouseleave":function () {
                _vm.isLangHovered = false
              },"click":function () {
                if (!_vm.isSubmenuBeingOpened) { _vm.toggleDesktopSubmenu('languages') }
              }}},[_c('svg-flag'),_vm._v(" "),(_vm.currentLanguageIndex !== null)?_c('span',[_vm._v("\n              "+_vm._s(_vm.propsData.languages[_vm.currentLanguageIndex].langCode)+"\n            ")]):_vm._e(),_vm._v(" "),_c('svg-arrow')],1)])])]),_vm._v(" "),_c('div',{class:[
        _vm.$style.headerDesktopDropdown,
        ( _obj$6 = {}, _obj$6[_vm.$style.headerDesktopDropdown_VISIBLE] = _vm.isDesktopDropdownVisible, _obj$6 )
      ],on:{"click":function($event){return _vm.closeDesktopSubmenu($event)},"mouseover":function($event){return _vm.closeDesktopSubmenu($event)}}},_vm._l((_vm.propsData.menu),function(menu,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.currentDesktopSubmenuIndex),expression:"index === currentDesktopSubmenuIndex"}],key:index},[(menu.submenu && !menu.isSubmenuInOverlay)?[_c('div',{class:_vm.$style.headerDesktopDropdownInner,style:({ transform: ("translateX(" + _vm.desktopDropdownShift + "px)") }),on:{"click":function($event){return _vm.closeDesktopSubmenu()}}},_vm._l((menu.submenu),function(item,index){
      var _obj, _obj$1, _obj$2;
return _c('nav-link',{key:index,class:[
                _vm.$style.headerDesktopDropdownInnerItem,
                ( _obj = {}, _obj[_vm.$style.headerDesktopDropdownInnerItem_ACTIVE] = _vm.isSubmenuLinkActive(
                    item.link
                  ), _obj ),
                ( _obj$1 = {}, _obj$1[_vm.$style.headerDesktopDropdownInnerItem_EXACT_ACTIVE] = _vm.isSubmenuLinkExactActive(
                    item.link
                  ), _obj$1 ),
                ( _obj$2 = {}, _obj$2[_vm.$style.headerDesktopDropdownInnerItem_WITH_IMG] = Boolean(item.imageUrl), _obj$2 )
              ],attrs:{"to":item.link}},[(item.imageUrl)?_c('img',{class:_vm.$style.headerDesktopSubmenuItemImage,attrs:{"src":item.imageUrl,"alt":item.label}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(item.label)+"\n              ")]),_vm._v(" "),(item.link.includes('http'))?_c('div',{class:_vm.$style.headerDesktopDropdownInnerItemExternal},[_c('svg-external-link')],1):_vm._e()])}),1)]:_vm._e()],2)}),0),_vm._v(" "),_c('div',{ref:"headerDesktopOverlay",class:[
        _vm.$style.headerDesktopOverlay,
        ( _obj$7 = {}, _obj$7[_vm.$style.headerDesktopOverlay_VISIBLE] = _vm.isDesktopOverlayVisible, _obj$7 ),
        ( _obj$8 = {}, _obj$8[_vm.$style.headerDesktopOverlay_SLIM] = _vm.isHeaderSlim, _obj$8 )
      ],on:{"click":function($event){return _vm.closeDesktopSubmenu($event)},"mouseover":function($event){return _vm.closeDesktopSubmenu($event)}}},[_c('div',{ref:"headerDesktopOverlayInner",staticClass:"frame-container",class:[
          _vm.$style.headerDesktopOverlayInner,
          ( _obj$9 = {}, _obj$9[_vm.$style.headerDesktopOverlayInner_SLIM] = _vm.isHeaderSlim, _obj$9 ),
          ( _obj$10 = {}, _obj$10[_vm.$style.headerDesktopOverlayInner_VISIBLE] = _vm.isDesktopOverlayVisible, _obj$10 )
        ]},[_c('div',{class:_vm.$style.headerDesktopOverlayInnerScrollable},[_vm._l((_vm.propsData.menu),function(menu,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.currentDesktopSubmenuIndex),expression:"index === currentDesktopSubmenuIndex"}],key:index},[(menu.submenu && menu.isSubmenuInOverlay)?[_c('div',{class:_vm.$style.headerDesktopSubmenuItems},_vm._l((menu.submenu),function(item,index){
        var _obj, _obj$1;
return _c('div',{key:index,on:{"click":function($event){return _vm.closeDesktopSubmenu()}}},[_c('nav-link',{class:[
                      _vm.$style.headerDesktopSubmenuItem,
                      ( _obj = {}, _obj[_vm.$style.headerDesktopSubmenuItem_ACTIVE] = _vm.isSubmenuLinkActive(
                          item.link
                        ), _obj ),
                      ( _obj$1 = {}, _obj$1[_vm.$style.headerDesktopSubmenuItem_EXACT_ACTIVE] = _vm.isSubmenuLinkExactActive(
                          item.link
                        ), _obj$1 )
                    ],attrs:{"to":item.link}},[(item.imageUrl)?_c('img',{class:_vm.$style.headerDesktopSubmenuItemImage,attrs:{"src":item.imageUrl,"alt":item.label}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n                      "+_vm._s(item.label)+"\n                    ")]),_vm._v(" "),(item.link.includes('http'))?_c('div',{class:_vm.$style.headerDesktopSubmenuItemExternal},[_c('svg-external-link')],1):_vm._e()])],1)}),0)]:_vm._e()],2)}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDesktopOverlayLanguagesActive),expression:"isDesktopOverlayLanguagesActive"}]},[_c('div',{class:_vm.$style.headerDesktopLanguagesItems},_vm._l((_vm.propsData.languages),function(language,index){
                    var _obj;
return _c('a',{key:index,class:[
                  _vm.$style.headerDesktopLanguagesItem,
                  ( _obj = {}, _obj[_vm.$style.headerDesktopLanguagesItem_CURRENT] = index === _vm.currentLanguageIndex, _obj )
                ],attrs:{"href":language.link}},[(language.flagUrl)?_c('img',{attrs:{"src":language.flagUrl,"alt":language.label}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n                  "+_vm._s(language.label)+"\n                ")])])}),0)])],2)])])]),_vm._v(" "),_c('div',{class:_vm.$style.headerMobile},[_c('div',{class:_vm.$style.headerMobileContent},[_c('div',{staticClass:"frame-container",class:_vm.$style.headerMobileContentInner},[_c('div',{class:_vm.$style.headerMobileContentInnerLogo,on:{"click":function () {
              if (_vm.isMobileOverlayVisible) { _vm.toggleMobileOverlay() }
            }}},[_c('nav-link',{attrs:{"to":_vm.propsData.logoUrl}},[_c('img',{attrs:{"src":"/logo.svg","alt":"MTD Logo"}})])],1),_vm._v(" "),_c('div',{class:_vm.$style.headerMobileRight},[_c('div',{class:[
              _vm.$style.headerMobileRightSearch,
              ( _obj$11 = {}, _obj$11[_vm.$style.headerMobileRightSearch_ACTIVE] = _vm.isSearchVisible, _obj$11 )
            ],attrs:{"role":"button","aria-label":_vm.propsData.i18n.search},on:{"click":function($event){return _vm.toggleSearch()}}},[_c('svg-search')],1),_vm._v(" "),_c('div',{class:[
              _vm.$style.headerMobileRightBurger,
              ( _obj$12 = {}, _obj$12[_vm.$style.headerMobileRightBurger_TOGGLED] = _vm.isMobileOverlayVisible, _obj$12 )
            ],attrs:{"role":"button"},on:{"click":function($event){return _vm.toggleMobileOverlay()}}},[_c('div',{class:[
                _vm.$style.headerMobileRightBurgerIcon,
                ( _obj$13 = {}, _obj$13[_vm.$style.headerMobileRightBurgerIcon_TOGGLED] = _vm.isMobileOverlayVisible, _obj$13 )
              ]})])])])]),_vm._v(" "),_c('div',{class:[
        _vm.$style.headerMobileOverlay,
        ( _obj$14 = {}, _obj$14[_vm.$style.headerMobileOverlay_VISIBLE] = _vm.isMobileOverlayVisible, _obj$14 )
      ]},[_c('div',{class:_vm.$style.headerMobileOverlayInner},[_c('div',_vm._l((_vm.propsData.menu),function(menuItem,index){return _c('div',{key:index,class:_vm.$style.headerMobileOverlayInnerMenuItem,on:{"click":function($event){!menuItem.submenu
                ? _vm.toggleMobileOverlay()
                : _vm.toggleMobileSubmenu($event)}}},[_c('nav-link',{class:_vm.$style.headerMobileOverlayInnerMenuItemLink,attrs:{"to":menuItem.link,"event":menuItem.submenu && menuItem.submenu.length ? '' : 'click'}},[_c('span',[_vm._v("\n                "+_vm._s(menuItem.label)+"\n              ")]),_vm._v(" "),(menuItem.submenu)?_c('svg-arrow'):_vm._e()],1),_vm._v(" "),(menuItem.submenu)?_c('div',{class:_vm.$style.headerMobileOverlayInnerMenuItemSubmenu},[(!menuItem.isSubmenuInOverlay)?_vm._l((menuItem.submenu),function(submenuItem,index){
                var _obj, _obj$1;
return _c('div',{key:index,class:_vm.$style.headerMobileOverlayInnerMenuItemSubmenuStandardItem,on:{"click":function($event){return _vm.toggleMobileOverlay()}}},[_c('nav-link',{class:[
                      _vm.$style.headerMobileOverlayInnerMenuItemSubmenuStandardItemLink,
                      ( _obj = {}, _obj[_vm.$style.headerMobileOverlayInnerMenuItemSubmenuStandardItemLink_ACTIVE] = _vm.isSubmenuLinkActive(
                          submenuItem.link
                        ), _obj ),
                      ( _obj$1 = {}, _obj$1[_vm.$style.headerMobileOverlayInnerMenuItemSubmenuStandardItemLink_EXACT_ACTIVE] = _vm.isSubmenuLinkExactActive(
                          submenuItem.link
                        ), _obj$1 )
                    ],attrs:{"to":submenuItem.link}},[_c('span',[_vm._v("\n                      "+_vm._s(submenuItem.label)+"\n                    ")]),_vm._v(" "),(submenuItem.link.includes('http'))?_c('div',{class:_vm.$style.headerMobileOverlayInnerMenuItemSubmenuStandardItemLinkExternal},[_c('svg-external-link')],1):_vm._e()])],1)}):[_c('div',{class:_vm.$style.headerMobileOverlayInnerMenuItemSubmenuWithIcons},_vm._l((menuItem.submenu),function(submenuItem,index){
                    var _obj, _obj$1;
return _c('div',{key:index,class:_vm.$style.headerMobileOverlayInnerMenuItemSubmenuWithIconsItem,on:{"click":function($event){return _vm.toggleMobileOverlay()}}},[_c('nav-link',{class:[
                        _vm.$style.headerMobileOverlayInnerMenuItemSubmenuWithIconsItemLink,
                        ( _obj = {}, _obj[_vm.$style.headerMobileOverlayInnerMenuItemSubmenuWithIconsItemLink_ACTIVE] = _vm.isSubmenuLinkActive(
                            submenuItem.link
                          ), _obj ),
                        ( _obj$1 = {}, _obj$1[_vm.$style.headerMobileOverlayInnerMenuItemSubmenuWithIconsItemLink_EXACT_ACTIVE] = _vm.isSubmenuLinkExactActive(
                            submenuItem.link
                          ), _obj$1 )
                      ],attrs:{"to":submenuItem.link}},[_c('div',{class:_vm.$style.headerMobileOverlayInnerMenuItemSubmenuWithIconsItemLinkIcon},[(submenuItem.imageUrl)?_c('img',{attrs:{"src":submenuItem.imageUrl}}):_vm._e()]),_vm._v(" "),_c('div',{class:_vm.$style.headerMobileOverlayInnerMenuItemSubmenuWithIconsItemLinkLabel},[_c('span',[_vm._v("\n                          "+_vm._s(submenuItem.label)+"\n                        ")]),_vm._v(" "),(submenuItem.link.includes('http'))?_c('div',{class:_vm.$style.headerMobileOverlayInnerMenuItemSubmenuWithIconsItemLinkLabelExternal},[_c('svg-external-link')],1):_vm._e()])])],1)}),0)]],2):_vm._e()],1)}),0),_vm._v(" "),_c('div',{class:_vm.$style.headerMobileOverlayInnerLang},[_c('div',{class:_vm.$style.headerMobileOverlayInnerLangLabel,attrs:{"role":"button","aria-label":_vm.propsData.i18n.ariaLabelRegion},on:{"click":function($event){return _vm.toggleMobileLanguages($event)}}},[_c('div',{class:_vm.$style.headerMobileOverlayInnerLangLabelLeft},[_c('svg-flag'),_vm._v(" "),(_vm.currentLanguageIndex !== null)?_c('span',[_vm._v("\n                "+_vm._s(_vm.propsData.languages[_vm.currentLanguageIndex].langCode)+"\n              ")]):_vm._e()],1),_vm._v(" "),_c('div',{class:_vm.$style.headerMobileOverlayInnerLangLabelRight},[_c('svg-arrow')],1)]),_vm._v(" "),_c('div',{class:_vm.$style.headerMobileOverlayInnerLangFlags},[_c('div',{class:_vm.$style.headerMobileOverlayInnerLangFlagsInner},_vm._l((_vm.propsData.languages),function(language,index){
                      var _obj;
return _c('a',{key:index,class:[
                  _vm.$style.headerMobileOverlayInnerLangFlagsInnerItem,
                  ( _obj = {}, _obj[_vm.$style.headerDesktopLanguagesItem_CURRENT] = index === _vm.currentLanguageIndex, _obj )
                ],attrs:{"href":language.link}},[(language.flagUrl)?_c('img',{attrs:{"src":language.flagUrl,"alt":language.label}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n                  "+_vm._s(language.label)+"\n                ")])])}),0)])])])])]),_vm._v(" "),_c('div',{class:[
      _vm.$style.search,
      ( _obj$15 = {}, _obj$15[_vm.$style.search_VISIBLE] = _vm.isSearchVisible, _obj$15 ),
      ( _obj$16 = {}, _obj$16[_vm.$style.search_SLIM] = _vm.isHeaderSlim, _obj$16 )
    ],on:{"click":function($event){return _vm.closeSearch($event)}}},[_c('div',{class:_vm.$style.searchInner},[(_vm.wasSearchOpenedAtLeastOnce)?_c('ui-header-search',{attrs:{"is-search-visible":_vm.isSearchVisible,"api-base-url":_vm.propsData.apiBaseUrl,"search-endpoint":_vm.propsData.searchEndpoint,"translation-label":_vm.propsData.i18n.search.label,"translation-input-placeholder":_vm.propsData.i18n.search.inputPlaceholder,"translation-no-results-found":_vm.propsData.i18n.search.inputPlaceholder,"translation-clear-results":_vm.propsData.i18n.search.clearResults,"translation-show-more":_vm.propsData.i18n.search.showMore,"translation-pages":_vm.propsData.i18n.search.pages,"translation-products":_vm.propsData.i18n.search.products,"translation-news":_vm.propsData.i18n.search.news},on:{"close-search":function($event){return _vm.closeSearch()}}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }