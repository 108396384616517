var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"component",class:_vm.$style.container},[(
      _vm.propsData.categories.length >= 2 ||
        _vm.propsData.filters[_vm.propsData.categories[0].id].length
    )?_c('div',{class:[
      _vm.$style.filters,
      ( _obj = {}, _obj[_vm.$style.filters_MOBILE_VISIBLE] = _vm.areMobileFiltersVisible, _obj )
    ]},[_c('div',{class:_vm.$style.filtersInner},[_c('div',{class:_vm.$style.filtersInnerClose,attrs:{"role":"button"},on:{"click":function($event){return _vm.toggleMobileFilters()}}},[_c('svg-cross-mark')],1),_vm._v(" "),_c('div',{class:_vm.$style.filtersInnerContent},[(_vm.propsData.categories.length >= 2)?[_c('div',{class:_vm.$style.filtersInnerContentTitle},[_vm._v("\n            "+_vm._s(_vm.propsData.i18n.subcategories)+"\n          ")]),_vm._v(" "),_c('ui-product-listing-option',{attrs:{"type":"radio","label":_vm.propsData.i18n.all,"isInactive":false,"isChecked":_vm.currentCategoryId === null,"amount":_vm.propsData.products.length},on:{"click":function () {
                _vm.areMobileFiltersVisible
                  ? (_vm.toggleMobileFilters(), _vm.scrollTopHandler())
                  : false

                _vm.currentCategoryId
                  ? _vm.categorySelectorHandler(_vm.currentCategoryId)
                  : false
              }}}),_vm._v(" "),_vm._l((_vm.propsData.categories),function(category,index){return _c('ui-product-listing-option',{key:index,attrs:{"type":"radio","label":category.label,"isInactive":false,"isChecked":_vm.currentCategoryId === category.id,"amount":_vm.projectedAmountsCategories[index]},on:{"click":function () {
                _vm.areMobileFiltersVisible
                  ? (_vm.toggleMobileFilters(), _vm.scrollTopHandler())
                  : false
                _vm.categorySelectorHandler(category.id)
              }}})})]:_vm._e(),_vm._v(" "),(_vm.currentCategoryId && _vm.selectedFilterOptions.length)?[_c('div',{class:_vm.$style.filtersInnerContentTitle},[_vm._v("\n            "+_vm._s(_vm.propsData.i18n.filters)+"\n          ")]),_vm._v(" "),_c('div',{class:[
              _vm.$style.filtersInnerContentClear,
              ( _obj$1 = {}, _obj$1[_vm.$style.filtersInnerContentClear_INACTIVE] = !_vm.isSomeOptionSelected, _obj$1 )
            ],attrs:{"role":"button"},on:{"click":function () {
                _vm.areMobileFiltersVisible
                  ? (_vm.toggleMobileFilters(), _vm.scrollTopHandler())
                  : false
                _vm.clearFiltersHandler()
              }}},[_vm._v("\n            "+_vm._s(_vm.propsData.i18n.clearFilters)+"\n          ")]),_vm._v(" "),_c('div',_vm._l((_vm.propsData.filters[
                _vm.currentCategoryId
              ]),function(filter,filterIndex){
              var _obj;
return _c('div',{key:(_vm.currentCategoryId + "-" + filterIndex),class:_vm.$style.filtersInnerContentFilter},[_c('div',{class:_vm.$style.filtersInnerContentFilterLabel},[_c('div',{class:_vm.$style.filtersInnerContentFilterLabelText},[_vm._v("\n                  "+_vm._s(filter.label)+"\n                ")]),_vm._v(" "),_c('div',{class:[
                    _vm.$style.filtersInnerContentFilterLabelClear,
                    ( _obj = {}, _obj[_vm.$style.filtersInnerContentFilterLabelClear_INACTIVE] = !_vm.selectedFilterOptions[
                        filterIndex
                      ].includes(1), _obj )
                  ],attrs:{"role":"button"},on:{"click":function () {
                      _vm.areMobileFiltersVisible
                        ? (_vm.toggleMobileFilters(), _vm.scrollTopHandler())
                        : false
                      _vm.clearSingleFilterHandler(filterIndex)
                    }}},[_c('svg-cross-mark')],1)]),_vm._v(" "),_c('div',_vm._l((filter.options),function(option,optionIndex){return _c('ui-product-listing-option',{key:optionIndex,attrs:{"type":filter.type,"label":option.label,"isInactive":_vm.projectedAmountsFilters &&
                    (
                      _vm.projectedAmountsFilters[filterIndex][optionIndex] === 0 ||
                      (
                        _vm.projectedAmountsFilters[filterIndex][optionIndex] === _vm.currentPageProducts.length &&
                        _vm.selectedFilterOptions[filterIndex][optionIndex] === 0 &&
                        filter.type === 'checkbox'
                      )
                    ) &&
                    _vm.selectedFilterOptions[filterIndex][optionIndex] !== 1,"isChecked":_vm.selectedFilterOptions[filterIndex][optionIndex] === 1,"amount":_vm.projectedAmountsFilters
                      ? _vm.projectedAmountsFilters[filterIndex][optionIndex]
                      : 0},on:{"click":function () {
                      _vm.areMobileFiltersVisible
                        ? (_vm.toggleMobileFilters(), _vm.scrollTopHandler())
                        : false
                      _vm.optionSelectorHandler(filterIndex, optionIndex)
                    }}})}),1)])}),0)]:_vm._e()],2)])]):_vm._e(),_vm._v(" "),_c('div',{class:_vm.$style.products},[_c('div',{class:_vm.$style.productsTop},[_c('div',{class:_vm.$style.productsTopLeft},[(_vm.propsData.arePricesVisible)?_c('div',{class:_vm.$style.productsTopLeftSelect},[_c('base-select',{attrs:{"label":_vm.propsData.i18n.sorting.label,"id":"sorting"},on:{"input":_vm.sortOptionsHandler},model:{value:(_vm.currentSortOption),callback:function ($$v) {_vm.currentSortOption=$$v},expression:"currentSortOption"}},[_c('option',{attrs:{"value":"default"}},[_vm._v(_vm._s(_vm.propsData.i18n.sorting.default))]),_vm._v(" "),_c('option',{attrs:{"value":"priceASC"}},[_vm._v(_vm._s(_vm.propsData.i18n.sorting.priceLowToHigh))]),_vm._v(" "),_c('option',{attrs:{"value":"priceDESC"}},[_vm._v(_vm._s(_vm.propsData.i18n.sorting.priceHighToLow))])])],1):_vm._e(),_vm._v(" "),(_vm.showPerPage)?_c('div',{class:_vm.$style.productsTopLeftPerPage},[_c('div',{class:_vm.$style.productsTopLeftPerPageCopy},[_vm._v(_vm._s(_vm.propsData.i18n.perPage))]),_vm._v(" "),_c('div',{class:[
              _vm.$style.productsTopLeftPerPageItem,
              ( _obj$2 = {}, _obj$2[_vm.$style.productsTopLeftPerPageItem_ACTIVE] = _vm.productsPerPage === 12, _obj$2 ),
              ( _obj$3 = {}, _obj$3[_vm.$style.productsTopLeftPerPageItem_HIDDEN_SORTER] = !_vm.propsData.arePricesVisible, _obj$3 )
            ],attrs:{"role":"button"},on:{"click":function($event){return _vm.productsPerPageHandler(12)}}},[_vm._v("\n            12\n          ")]),_vm._v(" "),_c('div',{class:[
              _vm.$style.productsTopLeftPerPageItem,
              ( _obj$4 = {}, _obj$4[_vm.$style.productsTopLeftPerPageItem_ACTIVE] = _vm.productsPerPage === 24, _obj$4 )
            ],attrs:{"role":"button"},on:{"click":function($event){return _vm.productsPerPageHandler(24)}}},[_vm._v("\n            24\n          ")])]):_vm._e()]),_vm._v(" "),(_vm.showPagination)?_c('div',{class:_vm.$style.productsTopRight},[_c('div',{class:_vm.$style.productsTopRightPagination},[_c('ui-product-listing-pagination',{attrs:{"current-page":_vm.currentPage,"pages-amount":_vm.totalPages,"prev-page-link":_vm.prevPageLink,"next-page-link":_vm.nextPageLink},on:{"prev":function($event){return _vm.paginationHandler('prev')},"next":function($event){return _vm.paginationHandler('next')}}})],1),_vm._v(" "),(
            _vm.propsData.categories.length >= 2 ||
              _vm.propsData.filters[_vm.propsData.categories[0].id].length
          )?_c('div',{class:_vm.$style.productsTopRightMobile,attrs:{"role":"button"},on:{"click":function($event){return _vm.toggleMobileFilters()}}},[_c('span',[_vm._v("\n            "+_vm._s(_vm.propsData.i18n.subcategoriesAndFilters)+"\n          ")])]):_vm._e()]):_vm._e()]),_vm._v(" "),_c('div',{class:[
        _vm.$style.productsGrid,
        ( _obj$5 = {}, _obj$5[_vm.$style.productsGrid_MOBILE] = _vm.isCurrentDeviceTouchEnabled, _obj$5 )
      ]},_vm._l((_vm.currentPageProducts),function(product,index){
      var _obj, _obj$1;
return _c('nav-link',{key:index,class:[
          _vm.$style.product,
          ( _obj = {}, _obj[_vm.$style.product_MOBILE] = _vm.isCurrentDeviceTouchEnabled, _obj ),
          ( _obj$1 = {}, _obj$1[_vm.$style.product_NO_FILTERS] = _vm.propsData.categories.length === 1 &&
              !_vm.propsData.filters[_vm.propsData.categories[0].id].length, _obj$1 )
        ],attrs:{"to":product.link}},[_c('div',{class:_vm.$style.productTop},[_c('div',{class:_vm.$style.productImage},[(product.image)?_c('img',{ref:"image",refInFor:true,staticClass:"lazyload",attrs:{"src":product.image.urlLqip,"data-srcset":((product.image.url) + " 1x, " + (product.image.urlRetina) + " 2x"),"data-src":product.image.url,"alt":product.image.alt,"width":product.image.width,"height":product.image.height,"crossorigin":"anonymous"}}):_vm._e()]),_vm._v(" "),(_vm.propsData.arePricesVisible)?_c('div',{class:_vm.$style.productPrice},[_vm._v("\n            "+_vm._s(product.priceFormatted)+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',{class:_vm.$style.productTitle},[_vm._v("\n            "+_vm._s(product.title)+"\n          ")])]),_vm._v(" "),_c('div',{class:_vm.$style.productAttribute},[_vm._v("\n          "+_vm._s(product.description)+"\n          "),_vm._v(" "),(_vm.isDebuggingModeEnabled)?_c('div',{staticStyle:{"color":"blue"}},[_vm._v("\n            "+_vm._s(JSON.stringify(product.attributes))+"\n          ")]):_vm._e()]),_vm._v(" "),(!_vm.isCurrentDeviceTouchEnabled)?_c('div',{class:_vm.$style.productLearnMoreDesktop},[_c('div',{class:_vm.$style.productLearnMoreDesktopButton},[_vm._v("\n            "+_vm._s(_vm.propsData.i18n.learnMore)+"\n          ")])]):_vm._e()])}),1),_vm._v(" "),(_vm.showPagination)?_c('div',{class:_vm.$style.productsBottom},[_c('ui-product-listing-pagination',{attrs:{"current-page":_vm.currentPage,"pages-amount":_vm.totalPages,"prev-page-link":_vm.prevPageLink,"next-page-link":_vm.nextPageLink,"component-reference":_vm.component},on:{"prev":function($event){return _vm.paginationHandler('prev')},"next":function($event){return _vm.paginationHandler('next')},"scroll-top":function($event){return _vm.scrollTopHandler()}}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }