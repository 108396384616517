




































































































































































































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

import { PropsData } from './UiForm.types'
import BaseCheckbox from 'mtd-ui/src/components/BaseCheckbox/BaseCheckbox.vue'
import BaseSelect from 'mtd-ui/src/components/BaseSelect/BaseSelect.vue'
import BaseRadio from 'mtd-ui/src/components/BaseRadio/BaseRadio.vue'
import SvgCrossMark from 'mtd-ui/src/icons/cross-mark.svg'

@Component({ components: { BaseCheckbox, BaseSelect, BaseRadio, SvgCrossMark } })
export default class UiForm extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  formValues: (string | boolean | number | undefined)[] = []
  formValuesFilenames: (string | undefined)[] = []
  formErrorMessages: string[][] = []
  requiredInputElements: boolean[] = []
  isSending: boolean = false
  formHasErrors = false
  serverHasError = false

  @Ref('form') form!: HTMLFormElement

  get selectFileLabel(): string {
    return this.propsData.i18n.selectFile || 'Select file'
  }

  handleFileInputChange(e: any, index: number): void {
    if (e.target.files && e.target.files.length) {
      this.formValues[index] = e.target.files[0].size
      this.formValuesFilenames[index] = e.target.files[0].name
    } else {
      this.formValues[index] = undefined
      this.formValuesFilenames[index] = undefined
    }

    this.validateFormValues()
  }

  handleFileRemove(e: any, index: number) {
    e.target.parentNode.parentNode.parentNode.firstChild.value = ''
    this.formValues[index] = undefined
    this.formValuesFilenames[index] = undefined
    this.formValuesFilenames = [...this.formValuesFilenames]
    // console.log( this.formValuesFilenames[index])
  }

  handleFileButtonClick(e: any): void {
    e.target.parentNode.previousElementSibling.click()
  }

  validateFormValues(checkUndefinedValues: boolean = false): void {
    for (let i = 0; i < this.propsData.inputElements.length; i++) {
      const currentFormValue = this.formValues[i]
      const currentValidators = this.propsData.inputElements[i].validators

      if (typeof currentValidators === 'undefined') {
        continue
      }

      if (typeof currentFormValue === 'undefined') {
        if (!checkUndefinedValues || !this.requiredInputElements[i]) {
          continue
        } else {
          this.formErrorMessages[i] = []
          for (let j = 0; j < currentValidators.length; j++) {
            this.formErrorMessages[i].push(currentValidators[j].errorMessage)
          }
          continue
        }
      }

      this.formErrorMessages[i] = []

      for (let j = 0; j < currentValidators.length; j++) {
        const currentValidator = currentValidators[j]

        if (
          currentValidator.identifier === 'NotEmpty' &&
          (currentFormValue === '' || currentFormValue === false)
        ) {
          this.formErrorMessages[i].push(currentValidator.errorMessage)
          continue
        }

        if (
          currentValidator.identifier === 'EmailAddress' &&
          typeof currentFormValue === 'string' &&
          currentFormValue.length
        ) {
          const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          if (!re.test(currentFormValue)) {
            this.formErrorMessages[i].push(currentValidator.errorMessage)
            continue
          }
        }

        if (
          currentValidator.identifier === 'RegularExpression' &&
          typeof currentFormValue === 'string' &&
          currentFormValue.length &&
          typeof currentValidator.options !== 'undefined' &&
          typeof currentValidator.options.regularExpression !== 'undefined'
        ) {
          const re = new RegExp(currentValidator.options.regularExpression)
          if (!re.test(currentFormValue)) {
            this.formErrorMessages[i].push(currentValidator.errorMessage)
            continue
          }
        }

        if (
          currentValidator.identifier === 'StringLength' &&
          typeof currentFormValue === 'string' &&
          typeof currentValidator.options !== 'undefined' &&
          typeof currentValidator.options.minimum === 'number' &&
          currentFormValue.length < currentValidator.options.minimum
        ) {
          this.formErrorMessages[i].push(currentValidator.errorMessage)
          continue
        }

        if (
          currentValidator.identifier === 'StringLength' &&
          typeof currentFormValue === 'string' &&
          typeof currentValidator.options !== 'undefined' &&
          typeof currentValidator.options.maximum === 'number' &&
          currentFormValue.length > currentValidator.options.maximum
        ) {
          this.formErrorMessages[i].push(currentValidator.errorMessage)
          continue
        }

        if (
          currentValidator.identifier === 'FileSize' &&
          typeof currentFormValue === 'number' &&
          typeof currentValidator.options !== 'undefined' &&
          typeof currentValidator.options.maximum === 'string'
        ) {
          const maximum = currentValidator.options.maximum
          let maxBytes: number | undefined

          if (maximum.charAt(maximum.length - 1) === 'M') {
            maxBytes =
              parseInt(maximum.substring(0, maximum.length - 1)) * 1000000
          }

          if (maxBytes && currentFormValue > maxBytes) {
            this.formErrorMessages[i].push(currentValidator.errorMessage)
            continue
          }
        }
      }
    }
    this.formErrorMessages = [...this.formErrorMessages]
  }

  formValidityCheck(): boolean {
    let hasErrors = false

    for (let i = 0; i < this.formErrorMessages.length; i++) {
      for (let j = 0; j < this.formErrorMessages[i].length; j++) {
        if (this.formErrorMessages[i][j].length) {
          hasErrors = true
          break
        }
      }
      if (hasErrors) break
    }

    this.formHasErrors = hasErrors

    return !hasErrors
  }

  async formSubmitHandler() {
    this.validateFormValues(true)

    if (!this.formValidityCheck()) {
      const offsetTop = this.form.offsetTop

      if (window.innerWidth < 768) {
        window.scrollTo({ top: offsetTop - 85, behavior: 'smooth' })
      } else {
        window.scrollTo({ top: offsetTop - 115, behavior: 'smooth' })
      }
      return
    }

    const formData = new FormData(this.form)

    this.isSending = true

    try {
      const response = await fetch((this as any).$store.$typo3.api.options.baseURL + this.propsData.actionUrl, {
        method: 'POST',
        body: formData
      }).then(r => r.json())

      if (response.content.colPos2[0].content.form.api.status === 'success') {
        this.$router.push(
          response.content.colPos2[0].content.form.api.actionAfterSuccess
            .redirectUri
        )
      } else {
        throw new Error()
      }
    } catch (err) {
      this.isSending = false
      this.serverHasError = true

      setTimeout(() => {
        this.serverHasError = false
      }, 30 * 1000)

      console.log(err)
    }
  }

  mounted() {
    this.formValues = [...new Array(this.propsData.inputElements.length)]

    for (let i = 0; i < this.propsData.inputElements.length; i++) {
      if (
        this.propsData.inputElements[i].type === 'SingleSelect'
      ) {
        this.formValues[i] = this.propsData.defaultCountryCode
        break
      }
    }

    for (let i = 0; i < this.propsData.inputElements.length; i++) {
      if (this.propsData.inputElements[i].defaultValue) {
        this.formValues[i] = this.propsData.inputElements[i].defaultValue
      }
    }

    this.formErrorMessages = [
      ...new Array(this.propsData.inputElements.length)
    ].map(() => [])

    for (let i = 0; i < this.propsData.inputElements.length; i++) {
      const validators = this.propsData.inputElements[i].validators

      if (typeof validators === 'undefined') {
        this.requiredInputElements.push(false)
        continue
      }

      let foundNotEmpty = false

      for (let j = 0; j < validators.length; j++) {
        if (validators[j].identifier === 'NotEmpty') {
          foundNotEmpty = true
          break
        }
      }
      this.requiredInputElements.push(foundNotEmpty)
    }
  }
}
