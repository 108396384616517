


















import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import gsap from 'gsap'

import { PropsData } from './UiTitleBar.types'

@Component
export default class UiTitleBar extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  isGsapAnimationEnabled = true

  @Ref('title') title!: HTMLDivElement
  @Ref('button') button!: HTMLDivElement

  onVisibilityChanged(
    isVisible: boolean,
    entry: IntersectionObserverEntry
  ): void {
    if (!this.isGsapAnimationEnabled || window.innerWidth < 768) return

    const tl = gsap.timeline({
      defaults: { duration: 0.8, ease: 'Power3.inOut' }
    })

    if (!isVisible) {
      if (entry.boundingClientRect.y < 0) return

      tl.to(this.title, { opacity: 0 })
      if (this.button) tl.to(this.button, { opacity: 0 }, 0)
    } else {
      tl.to(this.title, { opacity: 1 })
      if (this.button) tl.to(this.button, { opacity: 1 }, '<.2')
      this.isGsapAnimationEnabled = false
    }
  }
}
