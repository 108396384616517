// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Eq3r{margin-top:4em}._14SKE,._2Eq3r{text-align:center}._14SKE{text-transform:uppercase;font-family:\"Roboto Condensed\",sans-serif}._14SKE,._1C05t{margin:4rem 0}._1C05t{display:block;width:100%;height:auto}._2sSGO{margin-top:4rem}@media(min-width:768px){._3f8sU{display:-webkit-box;display:-ms-flexbox;display:flex;margin-right:-4rem}}._3f8sU>div{padding:4rem 0}@media(min-width:768px){._3f8sU>div{width:50%;margin-right:4rem}}.s6Dna{background:#f5f5f5;position:relative}.s6Dna:before{position:absolute;content:\"\";width:100vw;height:100%;background:#f5f5f5;top:0;-webkit-transform:translateX(-100%);transform:translateX(-100%)}._3QHoy{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}@media(min-width:768px){._3QHoy{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}}@media(min-width:990px){.tkn5_{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end}}.tkn5_>div{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.tkn5_>div a{margin:1.8rem 0 0;display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-weight:700;text-transform:uppercase}.tkn5_>div a:hover{color:#d50c2f}.tkn5_>div a:hover path{fill:#d50c2f}.tkn5_>div svg{margin:0 2rem 0 0}", ""]);
// Exports
exports.locals = {
	"title": "_2Eq3r",
	"date": "_14SKE",
	"image": "_1C05t",
	"info": "_2sSGO",
	"infoInner": "_3f8sU",
	"infoInnerFiles": "s6Dna",
	"infoInnerShare": "_3QHoy",
	"infoInnerShareContent": "tkn5_"
};
module.exports = exports;
