var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.onVisibilityChanged,
    throttle: 100,
    intersection: { threshold: 0.3 }
  }),expression:"{\n    callback: onVisibilityChanged,\n    throttle: 100,\n    intersection: { threshold: 0.3 }\n  }"}],ref:"container",class:[
    _vm.$style.container,
    ( _obj = {}, _obj[_vm.$style.container_NO_IMAGE] = !_vm.propsData.image, _obj )
  ]},[_c('div',{staticClass:"frame-container"},[_c('div',{class:_vm.$style.containerInner},[_c('div',{ref:"richText",class:[
          _vm.$style.richText,
          ( _obj$1 = {}, _obj$1[_vm.$style.richText_NO_IMAGE] = !_vm.propsData.image, _obj$1 )
        ]},[_c('base-rich-text',{attrs:{"rich-text":_vm.propsData.richText}})],1)])]),_vm._v(" "),(_vm.propsData.image)?_c('img',{ref:"image",class:[_vm.$style.image, ( _obj$2 = {}, _obj$2[_vm.$style.image_IE11FIX] = _vm.isIE11, _obj$2 )],attrs:{"src":_vm.propsData.image.url,"alt":_vm.propsData.image.alt,"width":_vm.propsData.image.width,"height":_vm.propsData.image.height,"data-lax-translate-y":"vh -140, (vh/2-elh/2) 0, -elh 140","data-lax-anchor":"self","crossorigin":"anonymous"}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }