var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.propsData.accordions),function(accordion,index){return _c('div',{key:accordion.label + index},[_c('div',{class:_vm.$style.accordionLabel,attrs:{"role":"button"},on:{"click":function($event){return _vm.toggleAccordion($event)}}},[_c('div',{class:_vm.$style.accordionLabelArrow},[_c('svg-arrow-2')],1),_vm._v("\n      "+_vm._s(accordion.label)+"\n    ")]),_vm._v(" "),_c('div',{class:_vm.$style.accordionContent},[_c('div',{class:_vm.$style.accordionContentInner},[(accordion.richText)?_c('base-rich-text',{attrs:{"rich-text":accordion.richText}}):_vm._e(),_vm._v(" "),(
            accordion.richText &&
              accordion.nestedAccordions &&
              accordion.nestedAccordions.length
          )?_c('div',{class:_vm.$style.accordionContentInnerSpacer}):_vm._e(),_vm._v(" "),(
            accordion.nestedAccordions && accordion.nestedAccordions.length
          )?_vm._l((accordion.nestedAccordions),function(nestedAccordion,index){return _c('div',{key:nestedAccordion.label + index,class:_vm.$style.nestedAccordion},[_c('div',{class:_vm.$style.nestedAccordionLabel,attrs:{"role":"button"},on:{"click":function($event){return _vm.toggleNestedAccordion($event)}}},[_c('div',{class:_vm.$style.nestedAccordionLabelArrow},[_c('svg-arrow-2')],1),_vm._v("\n              "+_vm._s(nestedAccordion.label)+"\n            ")]),_vm._v(" "),_c('div',{class:_vm.$style.nestedAccordionContent},[_c('div',{class:_vm.$style.nestedAccordionContentInner},[_c('base-rich-text',{attrs:{"rich-text":nestedAccordion.richText}})],1)])])}):_vm._e(),_vm._v(" "),(
            (accordion.nestedAccordions &&
              accordion.nestedAccordions.length &&
              accordion.features &&
              accordion.features.length) ||
              (accordion.richText &&
                accordion.features &&
                accordion.features.length)
          )?_c('div',{class:_vm.$style.accordionContentInnerSpacer}):_vm._e(),_vm._v(" "),(accordion.features && accordion.features.length)?_vm._l((accordion.features),function(feature,index){return _c('div',{key:feature.key + index,class:_vm.$style.feature},[_c('div',{class:_vm.$style.featureKey},[_vm._v(_vm._s(feature.key))]),_vm._v(" "),_c('div',{class:_vm.$style.featureValue},[_vm._v(_vm._s(feature.value))])])}):_vm._e()],2)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }