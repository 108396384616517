































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import gsap from 'gsap'

import { PropsData } from './UiTiles.types'

@Component
export default class UiTiles extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  isGsapAnimationEnabled = true

  @Ref('tile') tile!: HTMLDivElement

  onVisibilityChanged(
    isVisible: boolean,
    entry: IntersectionObserverEntry
  ): void {
    if (!this.isGsapAnimationEnabled || window.innerWidth < 768) return

    const tl = gsap.timeline({
      defaults: { ease: 'power3.inOut', duration: 0.8 }
    })

    if (!isVisible) {
      if (entry.boundingClientRect.y < 0) return

      tl.to(this.tile, { opacity: 0 })
    } else {
      tl.to(this.tile, { opacity: 1, stagger: 0.1 })
      this.isGsapAnimationEnabled = false
    }
  }
}
