var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",class:[
    _vm.$style.container,
    ( _obj = {}, _obj[_vm.$style.container_NO_RICH_TEXT] = !_vm.propsData.richText, _obj )
  ]},[(_vm.propsData.breadcrumbs && _vm.propsData.breadcrumbs.length >= 2)?_c('div',{ref:"breadcrumbs",class:[
      _vm.$style.breadcrumbs,
      ( _obj$1 = {}, _obj$1[_vm.$style.breadcrumbs_NO_RICH_TEXT] = !_vm.propsData.richText, _obj$1 )
    ]},[_c('div',{staticClass:"frame-container"},[_c('div',{class:_vm.$style.breadcrumbsInner},[_c('div',{class:_vm.$style.breadcrumbsInnerDesktop},_vm._l((_vm.propsData.breadcrumbs),function(page,index){
    var _obj, _obj$1;
return _c(index === _vm.propsData.breadcrumbs.length - 1 ? 'span' : 'nav-link',{key:index,tag:"component",class:[
              _vm.$style.breadcrumbsInnerDesktopItem,
              ( _obj = {}, _obj[_vm.$style.breadcrumbsInnerDesktopItem_WHITE] = _vm.propsData.image, _obj ),
              ( _obj$1 = {}, _obj$1[_vm.$style.breadcrumbsInnerDesktopItem_CURRENT] = index === _vm.propsData.breadcrumbs.length - 1, _obj$1 )
            ],attrs:{"to":index === _vm.propsData.breadcrumbs.length - 1 ? false : page.link},domProps:{"textContent":_vm._s(page.title)}})}),1),_vm._v(" "),(_vm.mobileBreadcrumbIndex !== null)?_c('div',{class:_vm.$style.breadcrumbsInnerMobile},[_c('nav-link',{class:[
              _vm.$style.breadcrumbsInnerMobileItem,
              ( _obj$2 = {}, _obj$2[_vm.$style.breadcrumbsInnerMobileItem_WHITE] = _vm.propsData.image, _obj$2 )
            ],attrs:{"to":_vm.propsData.breadcrumbs[_vm.mobileBreadcrumbIndex].link}},[_c('svg-arrow'),_vm._v(" "),_c('span',[_vm._v("\n              "+_vm._s(_vm.propsData.breadcrumbs[_vm.mobileBreadcrumbIndex].title)+"\n            ")])],1)],1):_vm._e()])])]):_vm._e(),_vm._v(" "),(_vm.propsData.image && _vm.propsData.richText)?_c('div',{class:_vm.$style.image},[_c('img',{ref:"image",staticClass:"lazyload",class:_vm.$style.image,attrs:{"src":_vm.propsData.image.urlLqip,"data-srcset":((_vm.propsData.image.url) + " 1x, " + (_vm.propsData.image.urlRetina) + " 2x"),"data-src":_vm.propsData.image.url,"alt":_vm.propsData.image.alt,"width":_vm.propsData.image.width,"height":_vm.propsData.image.height,"crossorigin":"anonymous"}})]):_vm._e(),_vm._v(" "),(_vm.propsData.richText)?_c('div',{ref:"richText",class:_vm.$style.richText},[_c('div',{class:[
        _vm.$style.richTextInner,
        ( _obj$3 = {}, _obj$3[_vm.$style.richTextInner_IMAGE] = _vm.propsData.image, _obj$3 )
      ]},[(_vm.propsData.is404)?_c('div',{class:_vm.$style.richTextInner404},[_c('img',{attrs:{"src":"/404.svg"}})]):_vm._e(),_vm._v(" "),_c('base-rich-text',{attrs:{"rich-text":_vm.propsData.richText}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }