





























































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import gsap from 'gsap'
import { Swiper } from 'swiper/js/swiper.esm.js'
import 'swiper/css/swiper.css'
const objectFitImages = require('object-fit-images')

import { PropsData } from './UiProductSlider.types'
import SvgArrow2 from 'mtd-ui/src/icons/arrow-2.svg'

@Component({
  components: {
    SvgArrow2
  }
})
export default class UiProductsSlider extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  swiperInstance: Swiper | undefined
  snapGridsLength = 0
  currentSnapIndex = 0
  isSwiperBeginning = true
  isSwiperEnd = false
  isProgressBarMovingToRight = false
  isGsapAnimationEnabled = true

  get areProgressBarAndNavigationArrowsVisible() {
    if (this.snapGridsLength > 1) {
      return true
    } else {
      return false
    }
  }

  @Ref('component') component!: HTMLDivElement
  @Ref('image') image!: HTMLImageElement
  @Ref('swiper') swiper!: HTMLDivElement

  @Watch('currentSnapIndex')
  onCurrentSnapIndexChanged(val: number, oldVal: number) {
    if (val > oldVal) {
      this.isProgressBarMovingToRight = true
    } else {
      this.isProgressBarMovingToRight = false
    }
  }

  slidesToShow(): number {
    const componentWidth = this.component.offsetWidth
    let slidesToShow: number

    if (componentWidth >= 1100) {
      slidesToShow = 4
    } else if (componentWidth >= 600) {
      slidesToShow = 3
    } else {
      slidesToShow = 2
    }

    return slidesToShow
  }

  slideGap(): number {
    let gap: number

    if (window.innerWidth >= 768) {
      gap = 40
    } else {
      gap = 15
    }

    return gap
  }

  initSwiper(): void {
    this.swiperInstance = new Swiper(this.swiper, {
      speed: 500,
      grabCursor: true,
      spaceBetween: this.slideGap(),
      slidesPerView: this.slidesToShow(),
      slidesPerGroup: this.slidesToShow(),
      on: {
        resize: () => {
          if (
            typeof this.swiperInstance === 'undefined' ||
            typeof this.component === 'undefined'
          )
            return

          this.swiperInstance.params.slidesPerView = this.slidesToShow()
          this.swiperInstance.params.slidesPerGroup = this.slidesToShow()
          this.swiperInstance.params.spaceBetween = this.slideGap()
        },
        slideChange: () => {
          if (typeof this.swiperInstance === 'undefined') return

          // @ts-ignore - due to incompletion of the library type definition
          this.currentSnapIndex = this.swiperInstance.snapIndex

          if (this.swiperInstance.isBeginning) {
            this.isSwiperBeginning = true
          } else {
            this.isSwiperBeginning = false
          }

          if (this.swiperInstance.isEnd) {
            this.isSwiperEnd = true
          } else {
            this.isSwiperEnd = false
          }
        }
      }
    })

    // @ts-ignore - due to incompletion of the library type definition
    this.snapGridsLength = this.swiperInstance.snapGrid.length
  }

  slideNext(): void {
    if (typeof this.swiperInstance === 'undefined') return
    this.swiperInstance.slideNext()
  }

  slidePrev(): void {
    if (typeof this.swiperInstance === 'undefined') return
    this.swiperInstance.slidePrev()
  }

  onVisibilityChanged(
    isVisible: boolean,
    entry: IntersectionObserverEntry
  ): void {
    if (!this.isGsapAnimationEnabled || window.innerWidth < 768) return

    const tl = gsap.timeline({
      defaults: { ease: 'power3.inOut', duration: 0.8 }
    })

    if (!isVisible) {
      if (entry.boundingClientRect.y < 0) return

      tl.to(this.component, { y: '5vh', opacity: 0 })
    } else {
      tl.to(this.component, { y: 0, opacity: 1 })
      this.isGsapAnimationEnabled = false
    }
  }

  mounted() {
    if (this.propsData.slides.length) {
      this.initSwiper()
    }

    objectFitImages(this.image)
  }
}
