// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Ti2I{position:relative}._155Wj{opacity:0;position:absolute;width:2rem;height:2rem}._1PbP_{width:1.8rem;height:1.8rem;border:.2rem solid #111;border-radius:.2rem;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-ms-flex-negative:0;flex-shrink:0}@media(min-width:768px){._1PbP_{width:2rem;height:2rem}}._1PbP_ svg{width:1.1rem;fill:transparent}@media(min-width:768px){._1PbP_ svg{width:1.2rem}}._155Wj:checked~._1PbP_{background:#d50c2f;border:.2rem solid #d50c2f}._155Wj:checked~._1PbP_ svg{fill:#fff}", ""]);
// Exports
exports.locals = {
	"checkbox": "_3Ti2I",
	"checkboxOriginal": "_155Wj",
	"checkboxCustom": "_1PbP_"
};
module.exports = exports;
