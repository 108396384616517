
































import { Component, Vue, Prop } from 'vue-property-decorator'

import { PropsData } from './UiFile.types'
import SvgDocument from 'mtd-ui/src/icons/document.svg'

@Component({
  components: {
    SvgDocument
  }
})
export default class UiFile extends Vue {
  @Prop({ required: true }) propsData!: PropsData
}
