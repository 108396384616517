// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".jqdpT:before{content:\"\";display:block;position:relative;width:0;height:11rem;margin-top:-11rem}._1FXr0:before{height:7rem;margin-top:-7rem}", ""]);
// Exports
exports.locals = {
	"anchor": "jqdpT",
	"anchor_DESCRIPTION": "_1FXr0"
};
module.exports = exports;
