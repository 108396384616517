var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ overflow: 'hidden' })},[_c('div',{staticClass:"frame-container"},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
        callback: _vm.onTextVisibilityChanged,
        throttle: 100,
        intersection: { threshold: 0.3 }
      }),expression:"{\n        callback: onTextVisibilityChanged,\n        throttle: 100,\n        intersection: { threshold: 0.3 }\n      }"}],class:[
        _vm.$style.text,
        ( _obj = {}, _obj[_vm.$style.text_RICH_TEXT_PROVIDED] = _vm.propsData.richText && !_vm.propsData.youtubeId, _obj ),
        ( _obj$1 = {}, _obj$1[_vm.$style.text_RICH_TEXT_AND_YOUTUBE_PROVIDED] = _vm.propsData.youtubeId && _vm.propsData.richText, _obj$1 ),
        ( _obj$2 = {}, _obj$2[_vm.$style.text_YOUTUBE_ONLY_PROVIDED] = _vm.propsData.youtubeId && !_vm.propsData.richText, _obj$2 )
      ]},[(_vm.propsData.heading)?_c('h3',{ref:"heading",class:_vm.$style.heading},[_vm._v("\n        "+_vm._s(_vm.propsData.heading)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.propsData.richText)?_c('div',{ref:"richText",class:_vm.$style.textContent},[_c('base-rich-text',{attrs:{"rich-text":_vm.propsData.richText}})],1):_vm._e()]),_vm._v(" "),(_vm.propsData.youtubeId)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
        callback: _vm.onVideoVisibilityChanged,
        throttle: 100,
        intersection: { threshold: 0.2 }
      }),expression:"{\n        callback: onVideoVisibilityChanged,\n        throttle: 100,\n        intersection: { threshold: 0.2 }\n      }"}],ref:"video",class:_vm.$style.video},[(_vm.isVideoActive)?_c('div',{class:_vm.$style.videoPlayer},[_c('iframe',{attrs:{"src":'https://www.youtube-nocookie.com/embed/' +
              _vm.propsData.youtubeId +
              '?autoplay=1',"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"}})]):_c('div',{class:_vm.$style.videoCover},[_c('img',{attrs:{"src":("https://i3.ytimg.com/vi/" + (_vm.propsData.youtubeId) + "/maxresdefault.jpg"),"alt":"YouTube Video","width":1280,"height":720}}),_vm._v(" "),(_vm.propsData.videoCoverText)?_c('div',{class:_vm.$style.videoCoverText},[_vm._v("\n          "+_vm._s(_vm.propsData.videoCoverText)+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{class:_vm.$style.videoCoverPlay,on:{"click":function($event){_vm.isVideoActive = true}}},[_c('div',{attrs:{"role":"button","title":"Play Video"},on:{"click":function($event){_vm.isVideoActive = true}}},[_c('svg-play')],1)])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }