




import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropGallery } from './CeImage.types'
import UiImage from 'mtd-ui/src/components/UiImage/UiImage.vue'
import { PropsData as UiImagePropsData } from 'mtd-ui/src/components/UiImage/UiImage.types'

@Component({ components: { UiImage } })
export default class CeImage extends Vue {
  @Prop() gallery!: PropGallery

  get uiImagePropsData(): UiImagePropsData {
    const imageObject = this.gallery.rows['1'].columns['1']

    return {
      image: {
        url: imageObject.publicUrl,
        urlLqip: imageObject.publicUrl,
        urlRetina: imageObject.publicUrl,
        alt: imageObject.properties.alternative
          ? imageObject.properties.alternative
          : 'Image',
        width: imageObject.properties.dimensions.width,
        height: imageObject.properties.dimensions.height
      }
    }
  }
}
