var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({class:[
    _vm.$style.option,
    ( _obj = {}, _obj[_vm.$style.option_INACTIVE] = _vm.isInactive, _obj )
  ],attrs:{"role":"button"}},_vm.$listeners),[(_vm.type === 'checkbox')?_c('div',{class:[
      _vm.$style.optionCheckbox,
      ( _obj$1 = {}, _obj$1[_vm.$style.optionCheckbox_ACTIVE] = _vm.isChecked, _obj$1 )
    ]},[_c('svg-check-mark')],1):_c('div',{class:[
      _vm.$style.optionRadio,
      ( _obj$2 = {}, _obj$2[_vm.$style.optionRadio_ACTIVE] = _vm.isChecked, _obj$2 )
    ]},[_c('div')]),_vm._v(" "),_c('span',[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_vm._v(" "),_c('div',{class:_vm.$style.optionAmount},[_c('span',[_vm._v(_vm._s(_vm.isInactive ? 0 : _vm.amount))])])])}
var staticRenderFns = []

export { render, staticRenderFns }