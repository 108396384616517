





























import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropsData } from './UiPageLinks.types'
import BaseLearnMore from '../BaseLearnMore/BaseLearnMore.vue'
import SvgExternalLink from 'mtd-ui/src/icons/external-link.svg'
import SvgDocument from 'mtd-ui/src/icons/document.svg'

@Component({ components: { BaseLearnMore, SvgExternalLink, SvgDocument } })
export default class UiPageLinks extends Vue {
  @Prop({ required: true }) propsData!: PropsData
}
