

































































































































































































































































































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import 'url-search-params-polyfill'
const objectFitImages = require('object-fit-images')

import { PropsData, Product } from './UiProductListing.types'
import BaseSelect from '../BaseSelect/BaseSelect.vue'
import UiProductListingOption from './UiProductListingOption/UiProductListingOption.vue'
import UiProductListingPagination from './UiProductListingPagination/UiProductListingPagination.vue'
import SvgCrossMark from 'mtd-ui/src/icons/cross-mark.svg'

type SortOptions = 'default' | 'priceASC' | 'priceDESC'

@Component({
  components: {
    BaseSelect,
    UiProductListingOption,
    UiProductListingPagination,
    SvgCrossMark
  }
})
export default class UiProductListing extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  @Ref('component') component!: HTMLDivElement
  @Ref('image') image!: HTMLImageElement

  // Temporary debuggin feature
  isDebuggingModeEnabled = false
  areMobileFiltersVisible = false
  isCurrentDeviceTouchEnabled = false
  currentCategoryId: string | null = null
  selectedFilterOptions: (1 | 0)[][] = []
  filteredAndSortedProducts: Product[] = []
  currentPage = 1
  productsPerPage: number = 12
  currentSortOption: SortOptions = 'default'

  get projectedAmountsCategories(): number[] {
    const amounts: number[] = new Array(this.propsData.categories.length)

    for (let i = 0; i < this.propsData.categories.length; i++) {
      if (this.currentCategoryId === this.propsData.categories[i].id) {
        amounts[i] = this.filteredAndSortedProducts.length
        continue
      }

      amounts[i] = 0

      this.propsData.products.forEach(p => {
        if (p.categoryId === this.propsData.categories[i].id) {
          amounts[i] += 1
        }
      })
    }

    return amounts
  }

  get projectedAmountsFilters(): number[][] | undefined {
    if (this.currentCategoryId === null) return

    const projectedAmounts: number[][] = []

    for (let i = 0; i < this.selectedFilterOptions.length; i++) {
      projectedAmounts.push([])

      for (let j = 0; j < this.selectedFilterOptions[i].length; j++) {
        const selectedFilterOptionsDeepCopy = JSON.parse(
          JSON.stringify(this.selectedFilterOptions)
        )

        let isRadioType =
          this.propsData.filters[this.currentCategoryId][i].type === 'radio'

        if (isRadioType) {
          for (let k = 0; k < this.selectedFilterOptions[i].length; k++) {
            selectedFilterOptionsDeepCopy[i][k] = 0
          }
        }

        selectedFilterOptionsDeepCopy[i][j] = 1

        projectedAmounts[i].push(
          this.getFilteredAndSortedProducts(selectedFilterOptionsDeepCopy)
            .length
        )
      }
    }
    return projectedAmounts
  }

  get isSomeOptionSelected(): boolean {
    let isSomeOptionSelected = false
    for (let i = 0; i < this.selectedFilterOptions.length; i++) {
      for (let j = 0; j < this.selectedFilterOptions[i].length; j++) {
        if (this.selectedFilterOptions[i][j] === 1) {
          isSomeOptionSelected = true
          break
        }
      }
      if (isSomeOptionSelected) break
    }

    return isSomeOptionSelected
  }

  get totalPages(): number {
    return Math.ceil(
      this.filteredAndSortedProducts.length / this.productsPerPage
    )
  }

  get showPagination(): boolean {
    return this.totalPages > 1
  }

  get showPerPage(): boolean {
    return this.filteredAndSortedProducts.length > 12
  }

  get currentPageProducts(): Product[] {
    if (this.filteredAndSortedProducts.length <= this.productsPerPage) {
      return this.filteredAndSortedProducts
    } else {
      const startIndex = this.currentPage * this.productsPerPage - this.productsPerPage // prettier-ignore
      const endIndex = startIndex + this.productsPerPage
      return this.filteredAndSortedProducts.slice(startIndex, endIndex) // prettier-ignore
    }
  }

  get prevPageLink(): string {
    // Supress errors on storybook
    if (typeof this.$route === 'undefined') return ''

    if (this.filteredAndSortedProducts.length <= this.productsPerPage) {
      return this.$route.fullPath
    }

    if (this.currentPage <= this.totalPages) {
      if (this.currentPage > 1) {
        return this.$route.fullPath.replace(
          `page=${this.currentPage}`,
          `page=${this.currentPage - 1}`
        )
      }
    }

    return this.$route.fullPath
  }

  get nextPageLink(): string {
    // Supress errors on storybook
    if (typeof this.$route === 'undefined') return ''

    if (this.filteredAndSortedProducts.length <= this.productsPerPage) {
      return this.$route.fullPath
    }

    if (this.currentPage < this.totalPages) {
      if (this.$route.fullPath.includes('page=')) {
        return this.$route.fullPath.replace(
          `page=${this.currentPage}`,
          `page=${this.currentPage + 1}`
        )
      } else {
        if (this.$route.fullPath.includes('?')) {
          return this.$route.fullPath + `&page=${this.currentPage + 1}`
        } else {
          return this.$route.fullPath + `?page=${this.currentPage + 1}`
        }
      }
    }

    return this.$route.fullPath
  }

  paginationHandler(direction: 'prev' | 'next'): void {
    if (typeof this.$router === 'undefined') return

    let nextPage

    if (direction === 'prev' && this.currentPage > 1) {
      nextPage = this.currentPage - 1
    } else if (direction === 'next' && this.currentPage < this.totalPages) {
      nextPage = this.currentPage + 1
    }

    if (typeof nextPage !== 'undefined') {
      if (nextPage === 1) {
        this.$router.push({ query: { ...this.$route.query, page: undefined } })
      } else {
        this.$router.push({
          query: { ...this.$route.query, page: nextPage.toString() }
        })
      }
    }
  }

  sortOptionsHandler(): void {
    if (typeof this.$router === 'undefined') return

    if (this.currentSortOption === 'default') {
      this.$router.push({
        query: { ...this.$route.query, sortOpt: undefined, page: undefined }
      })
    } else {
      this.$router.push({
        query: {
          ...this.$route.query,
          sortOpt: this.currentSortOption,
          page: undefined
        }
      })
    }

    if (window.innerWidth < 768) {
      this.scrollTopHandler()
    }
  }

  productsPerPageHandler(amount: number): void {
    if (typeof this.$router === 'undefined') return

    if (amount === 24) {
      this.$router.push({
        query: { ...this.$route.query, perPage: '24', page: undefined }
      })
    } else {
      this.$router.push({
        query: { ...this.$route.query, perPage: undefined, page: undefined }
      })
    }
  }

  clearFiltersHandler(): void {
    if (typeof this.$router === 'undefined') return

    this.$router.push({
      query: { ...this.$route.query, filterOpts: undefined, page: undefined }
    })
  }

  setCategory(catId: string | null): void {
    if (catId === null) {
      this.currentCategoryId = null
      this.selectedFilterOptions = []
    } else {
      this.currentCategoryId = catId
      this.selectedFilterOptions = new Array(
        this.propsData.filters[catId].length
      )

      for (let i = 0; i < this.selectedFilterOptions.length; i++) {
        this.selectedFilterOptions[i] = new Array(
          this.propsData.filters[catId][i].options.length
        )

        for (let j = 0; j < this.selectedFilterOptions[i].length; j++) {
          this.selectedFilterOptions[i][j] = 0
        }
      }
    }

    this.filteredAndSortedProducts = this.getFilteredAndSortedProducts(
      this.selectedFilterOptions
    )
  }

  categorySelectorHandler(catId: string): void {
    if (typeof this.$router === 'undefined') return

    if (catId === this.currentCategoryId) {
      this.$router.push({
        query: {
          ...this.$route.query,
          filterOpts: undefined,
          catId: undefined,
          page: undefined
        }
      })
    } else {
      this.$router.push({
        query: {
          ...this.$route.query,
          filterOpts: undefined,
          catId,
          page: undefined
        }
      })
    }
  }

  setUrlParamFilters(): void {
    if (typeof this.$router === 'undefined') return

    const filterOptsStringified = this.selectedFilterOptions
      .map(el => el.join(''))
      .join('-')

    this.$router.push({
      query: {
        ...this.$route.query,
        filterOpts: filterOptsStringified,
        page: undefined
      }
    })
  }

  optionSelectorHandler(filterIndex: number, optionIndex: number): void {
    if (this.currentCategoryId === null) return

    let isRadioType =
      this.propsData.filters[this.currentCategoryId][filterIndex].type ===
      'radio'

    if (isRadioType) {
      for (let i = 0; i < this.selectedFilterOptions[filterIndex].length; i++) {
        if (i === optionIndex) continue

        this.selectedFilterOptions[filterIndex][i] = 0
      }
    }

    if (this.selectedFilterOptions[filterIndex][optionIndex] === 1) {
      this.selectedFilterOptions[filterIndex][optionIndex] = 0
    } else {
      this.selectedFilterOptions[filterIndex][optionIndex] = 1
    }

    this.setUrlParamFilters()
  }

  clearSingleFilterHandler(filterIndex: number): void {
    for (let i = 0; i < this.selectedFilterOptions[filterIndex].length; i++) {
      this.selectedFilterOptions[filterIndex][i] = 0
    }

    this.setUrlParamFilters()
  }

  getFilteredAndSortedProducts(selFilterOpts: (1 | 0)[][]): Product[] {
    return this.propsData.products
      .filter(product => {
        if (!this.currentCategoryId) return true

        if (this.currentCategoryId !== product.categoryId) return false

        let leaveCurrentProduct = true

        for (let i = 0; i < selFilterOpts.length; i++) {
          let currentFilterHasSelectedOption = false

          for (let j = 0; j < selFilterOpts[i].length; j++) {
            if (selFilterOpts[i][j]) {
              currentFilterHasSelectedOption = true
              break
            }
          }

          if (!currentFilterHasSelectedOption) continue

          let atLeastOneOptionMatch = false

          for (let j = 0; j < selFilterOpts[i].length; j++) {
            if (selFilterOpts[i][j]) {
              const attribute = this.propsData.filters[this.currentCategoryId][i].options[j].attribute // prettier-ignore
              const optionComparisonMethod = this.propsData.filters[this.currentCategoryId][i].options[j].comparison // prettier-ignore
              const optionValue = this.propsData.filters[this.currentCategoryId][i].options[j].value // prettier-ignore

              let currentProductAttributeValue
              for (let k = 0; k < product.attributes.length; k++) {
                if (product.attributes[k][0] === attribute) {
                  currentProductAttributeValue = product.attributes[k][1]
                  break
                }
              }

              if (!currentProductAttributeValue) break

              if (
                optionComparisonMethod === 'eq' &&
                currentProductAttributeValue === optionValue
              ) {
                atLeastOneOptionMatch = true
                break
              }

              if (
                optionComparisonMethod === 'in' &&
                Array.isArray(currentProductAttributeValue) &&
                !currentProductAttributeValue.includes(optionValue)
              ) {
                atLeastOneOptionMatch = true
                break
              }

              if (
                optionComparisonMethod === 'lt' &&
                currentProductAttributeValue < optionValue
              ) {
                atLeastOneOptionMatch = true
                break
              }

              if (
                optionComparisonMethod === 'lte' &&
                currentProductAttributeValue <= optionValue
              ) {
                atLeastOneOptionMatch = true
                break
              }

              if (
                optionComparisonMethod === 'gt' &&
                currentProductAttributeValue > optionValue
              ) {
                atLeastOneOptionMatch = true
                break
              }

              if (
                optionComparisonMethod === 'gte' &&
                currentProductAttributeValue >= optionValue
              ) {
                atLeastOneOptionMatch = true
                break
              }
            }
          }

          if (!atLeastOneOptionMatch) {
            leaveCurrentProduct = false
          }
        }

        return leaveCurrentProduct
      })
      .sort((firstProd, secondProd) => {
        if (this.currentSortOption === 'priceASC') {
          return firstProd.price - secondProd.price
        } else if (this.currentSortOption === 'priceDESC') {
          return secondProd.price - firstProd.price
        } else {
          return 1
        }
      })
  }

  toggleMobileFilters(): void {
    if (!this.areMobileFiltersVisible) {
      this.areMobileFiltersVisible = true
      document.body.style.overflow = 'hidden'
    } else {
      this.areMobileFiltersVisible = false
      document.body.style.overflow = 'visible'
    }
  }

  scrollTopHandler(): void {
    const offsetTop = this.component.offsetTop

    Vue.nextTick(() => {
      if (window.innerWidth < 768) {
        window.scrollTo(0, offsetTop - 71) // +1 because of header border height
      } else {
        window.scrollTo(0, offsetTop - 131) // +1 because of header border height
      }
    })
  }

  created() {
    if (typeof this.$route !== 'undefined') {
      const catId = this.$route.query.catId
      const filterOpts = this.$route.query.filterOpts
      const sortOpt = this.$route.query.sortOpt
      const perPage = this.$route.query.perPage
      const page = this.$route.query.page
      // Temporary debuggin feature
      const debug = this.$route.query.debug

      if (catId && typeof catId === 'string') {
        this.setCategory(catId)
      } else if (this.propsData.categories.length === 1) {
        this.setCategory(this.propsData.categories[0].id)
      } else {
        this.setCategory(null)
      }

      if (filterOpts && typeof filterOpts === 'string') {
        this.selectedFilterOptions = filterOpts
          .split('-')
          .map(el => el.split('').map(el => (el === '1' ? 1 : 0)))
        this.filteredAndSortedProducts = this.getFilteredAndSortedProducts(
          this.selectedFilterOptions
        )
      }

      if (
        sortOpt &&
        typeof sortOpt === 'string' &&
        (sortOpt === 'priceASC' || sortOpt === 'priceDESC')
      ) {
        this.currentSortOption = sortOpt
      }

      if (perPage && typeof perPage === 'string') {
        this.productsPerPage = parseInt(perPage)
      }

      if (page && typeof page === 'string') {
        this.currentPage = parseInt(page)
      }

      // Temporary debuggin feature
      if (debug && debug === 'true') {
        this.isDebuggingModeEnabled = true
      }

    } else {
      // storybook only
      if (this.propsData.categories.length === 1) {
        this.setCategory(this.propsData.categories[0].id)
      } else {
        this.setCategory(null)
      }
    }

    this.filteredAndSortedProducts = this.getFilteredAndSortedProducts(
      this.selectedFilterOptions
    )
  }

  mounted() {
    if ('ontouchstart' in window) {
      this.isCurrentDeviceTouchEnabled = true
    }

    objectFitImages(this.image)
  }
}
