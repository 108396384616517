











































































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import gsap from 'gsap'
const objectFitImages = require('object-fit-images')

import { PropsData } from './UiVideos.types'
import SvgArrow2 from 'mtd-ui/src/icons/arrow-2.svg'
import SvgCrossMark from 'mtd-ui/src/icons/cross-mark.svg'
import SvgPlay from 'mtd-ui/src/icons/play.svg'

@Component({
  components: {
    SvgArrow2,
    SvgCrossMark,
    SvgPlay
  }
})
export default class UiVideos extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  isOverlayVisible = false
  isIframeVisible = false
  currentVideoIndex = 0
  isGsapAnimationEnabled = true

  @Ref('overlayInner') overlayInner!: HTMLDivElement
  @Ref('videos') videos!: HTMLDivElement
  @Ref('image') image!: HTMLImageElement

  get nextVideoIndex() {
    if (this.currentVideoIndex === this.propsData.videos.length - 1) {
      return 0
    } else {
      return this.currentVideoIndex + 1
    }
  }

  toggleOverlayVisibility(event?: any): void {
    if (event && this.isOverlayVisible) {
      if (
        this.isOverlayVisible &&
        (event.target.classList.contains(this.$style.overlay) ||
          event.target.classList.contains(this.$style.overlayInnerClose))
      ) {
        event.stopPropagation()
        this.isOverlayVisible = false
        this.isIframeVisible = false
        return
      }
    } else {
      this.isOverlayVisible = true
      // This is made to eliminate a glitch during the overlay opening animation
      setTimeout(() => {
        if (this.isOverlayVisible) {
          this.isIframeVisible = true
        }
      }, 100)
    }
  }

  prevVideo(): void {
    if (this.currentVideoIndex === 0) {
      this.currentVideoIndex = this.propsData.videos.length - 1
    } else {
      this.currentVideoIndex -= 1
    }
  }

  nextVideo(): void {
    if (this.currentVideoIndex === this.propsData.videos.length - 1) {
      this.currentVideoIndex = 0
    } else {
      this.currentVideoIndex += 1
    }
  }

  onVisibilityChanged(
    isVisible: boolean,
    entry: IntersectionObserverEntry
  ): void {
    if (!this.isGsapAnimationEnabled || window.innerWidth < 768) return

    const tl = gsap.timeline({
      defaults: { ease: 'power3.inOut', duration: 0.8 }
    })

    if (!isVisible) {
      if (entry.boundingClientRect.y < 0) return

      tl.to(this.videos, { y: '5vh', opacity: 0 })
    } else {
      tl.to(this.videos, { y: 0, opacity: 1 })
      this.isGsapAnimationEnabled = false
    }
  }

  mounted() {
    objectFitImages(this.image)
  }
}
