




import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropVideo } from './CeMtdbase_singlevideo.types'
import UiVideos from 'mtd-ui/src/components/UiVideos/UiVideos.vue'
import { PropsData as UiVideosPropsData } from 'mtd-ui/src/components/UiVideos/UiVideos.types'

@Component({ components: { UiVideos } })
export default class CeMtdbase_singlevideo extends Vue {
  @Prop() video!: PropVideo

  get uiVideosPropsData(): UiVideosPropsData {
    const ytId = this.video.publicUrl.match(/embed\/(.*)\?/)
    const videos = []

    if (ytId && ytId.length === 2) {
      videos.push({
        youtubeId: ytId[1],
        title: this.video.properties.title
      })
    }

    return {
      videos,
      i18n: {
        // there is never more than one video, so naviation is not necessary
        next: '',
        prev: ''
      }
    }
  }
}
