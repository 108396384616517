
















































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import gsap from 'gsap'

import { PropsData } from './UiTextWithVideo.types'
import SvgPlay from 'mtd-ui/src/icons/play.svg'

@Component({
  components: {
    SvgPlay
  }
})
export default class UiTextWithVideo extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  isVideoActive = false
  isGsapAnimationEnabledText = true
  isGsapAnimationEnabledVideo = true

  @Ref('heading') heading!: HTMLDivElement
  @Ref('richText') richText!: HTMLDivElement
  @Ref('video') video!: HTMLDivElement

  onTextVisibilityChanged(
    isVisible: boolean,
    entry: IntersectionObserverEntry
  ): void {
    if (!this.isGsapAnimationEnabledText || window.innerWidth < 768) return

    const tl = gsap.timeline({
      defaults: { ease: 'power3.inOut', duration: 0.8 }
    })

    if (!isVisible) {
      if (entry.boundingClientRect.y < 0) return

      if (this.heading) tl.to(this.heading, { opacity: 0 })
      if (this.richText) tl.to(this.richText, { y: '5vh', opacity: 0 }, 0)
    } else {
      if (this.heading) tl.to(this.heading, { opacity: 1 })
      if (this.richText) tl.to(this.richText, { y: 0, opacity: 1 }, '<.4')
      this.isGsapAnimationEnabledText = false
    }
  }

  onVideoVisibilityChanged(
    isVisible: boolean,
    entry: IntersectionObserverEntry
  ): void {
    if (!this.isGsapAnimationEnabledVideo || window.innerWidth < 768) return

    const tl = gsap.timeline({
      defaults: { ease: 'power3.inOut', duration: 0.8 }
    })

    if (!isVisible) {
      if (entry.boundingClientRect.y < 0) return

      tl.to(this.video, { y: '5vh', opacity: 0 })
    } else {
      tl.to(this.video, { y: 0, opacity: 1 })
      this.isGsapAnimationEnabledVideo = false
    }
  }
}
