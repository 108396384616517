
















































import { Component, Vue, Prop } from 'vue-property-decorator'

import SvgArrow from 'mtd-ui/src/icons/arrow.svg'

@Component({
  components: {
    SvgArrow
  }
})
export default class UiProductListingPagination extends Vue {
  @Prop({ required: true }) currentPage!: number
  @Prop({ required: true }) pagesAmount!: number
  @Prop({ required: true }) nextPageLink!: string
  @Prop({ required: true }) prevPageLink!: string

  prevHandler(): void {
    this.$emit('prev')
  }

  nextHandler(): void {
    this.$emit('next')
  }

  scrollTopHandler(): void {
    this.$emit('scroll-top')
  }
}
