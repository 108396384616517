















import { Component, Vue, Prop } from 'vue-property-decorator'

import SvgArrow from 'mtd-ui/src/icons/arrow.svg'

@Component({
  components: {
    SvgArrow
  }
})
export default class BaseSelect extends Vue {
  @Prop({ type: String }) value?: string
  @Prop({ type: String, required: true }) id!: string
  @Prop({ type: String }) label?: string
  @Prop() name?: string
}
