// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Rge9{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;overflow:hidden;background:#111}._3Shvn{background:#e8e8e8}._2J4zQ{position:absolute;opacity:.3;left:0;width:100%;height:calc(100% + 70px);-o-object-fit:cover;object-fit:cover;font-family:\"object-fit: cover;\"}._3swTD{top:0;height:100%}._2Uhyt{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;position:relative;min-height:25rem}@media(min-width:990px){._2Uhyt{min-height:40rem}}@media(min-width:1280px){._2Uhyt{min-height:50rem}}._2nvQh{color:#fff;position:relative;z-index:1;max-width:50rem;margin:4rem 0}@media(min-width:768px){._2nvQh{max-width:55rem;margin:10rem 0}}@media(min-width:990px){._2nvQh{max-width:70rem;margin:12rem 0}}@media(min-width:1280px){._2nvQh{max-width:75rem;margin:14rem}}._2nvQh div{text-align:center}.aEzbM{color:#111}", ""]);
// Exports
exports.locals = {
	"container": "_3Rge9",
	"container_NO_IMAGE": "_3Shvn",
	"image": "_2J4zQ",
	"image_IE11FIX": "_3swTD",
	"containerInner": "_2Uhyt",
	"richText": "_2nvQh",
	"richText_NO_IMAGE": "aEzbM"
};
module.exports = exports;
