













import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropsData } from './UiImage.types'

@Component
export default class UiImage extends Vue {
  @Prop({ required: true }) propsData!: PropsData
}
