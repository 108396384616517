// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Yhfvy{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap}@media(min-width:768px){.Yhfvy{margin-right:-4rem;margin-bottom:-4rem}}._1U1jq{width:100%;padding-bottom:1.5rem;border-bottom:.1rem solid #e8e8e8;margin-bottom:1.5rem;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex}@media(min-width:768px){._1U1jq{width:calc(50% - 4rem);margin-right:4rem;margin-bottom:4rem}}@media(max-width:767px){._1U1jq:last-child{margin-bottom:0}}._1U1jq:hover ._2BurP{color:#d50c2f}._2XMd2{-webkit-transition:inherit;transition:inherit;margin-right:1.5rem}._2ZVpe{width:9rem}@media(min-width:768px){._2ZVpe{width:12rem}}@media(min-width:990px){._2ZVpe{width:17rem}}@media(min-width:1280px){._2ZVpe{width:21rem}}._2ZVpe img{width:100%;height:100%;-o-object-fit:contain;object-fit:contain;font-family:\"object-fit: contain;\"}._3l6ww{-webkit-box-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}._3l6ww,.rKEdl,._2BurP{-webkit-transition:inherit;transition:inherit}._2BurP{font-size:1.8rem;font-family:\"Roboto Condensed\",sans-serif;margin:0}@media(min-width:768px){._2BurP{font-size:2rem}}@media(min-width:990px){._2BurP{font-size:2.2rem}}@media(min-width:1280px){._2BurP{font-size:2.5rem}}@media(max-width:767px){._2BurP{font-size:1.8rem}}._1OLkD{margin-top:.2em}._1Hheb{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-transition:inherit;transition:inherit;margin-top:1.5rem;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}", ""]);
// Exports
exports.locals = {
	"products": "Yhfvy",
	"product": "_1U1jq",
	"productContentTextTitle": "_2BurP",
	"productImage": "_2XMd2",
	"productImageInner": "_2ZVpe",
	"productContent": "_3l6ww",
	"productContentText": "rKEdl",
	"productContentTextRichText": "_1OLkD",
	"productContentLearnMore": "_1Hheb"
};
module.exports = exports;
