











































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import gsap from 'gsap'
const lax = require('lax.js')
const objectFitImages = require('object-fit-images')

import { PropsData } from './UiCallToAction.types'

@Component
export default class UiCallToAction extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  isGsapAnimationEnabled = true
  isIE11 = false

  @Ref('container') container!: HTMLDivElement
  @Ref('image') image!: HTMLImageElement
  @Ref('richText') richText!: HTMLDivElement

  onVisibilityChanged(
    isVisible: boolean,
    entry: IntersectionObserverEntry
  ): void {
    if (!this.isGsapAnimationEnabled || window.innerWidth < 768) return

    const tl = gsap.timeline({
      defaults: { ease: 'power3.inOut', duration: 0.8 }
    })

    if (!isVisible) {
      if (entry.boundingClientRect.y < 0) return

      tl.to(this.container, { y: '5vh', opacity: 0 })
      if (this.image) {
        tl.to(this.richText, { opacity: 0 }, 0)
      }
    } else {
      tl.to(this.container, { y: 0, opacity: 1 })
      if (this.image) {
        tl.to(this.richText, { opacity: 1 }, '<.2')
      }
      this.isGsapAnimationEnabled = false
    }
  }

  laxScrollHandler(): void {
    lax.update(window.scrollY)
  }

  mounted() {
    // @ts-ignore
    this.isIE11 = !!window.MSInputMethodContext && !!document.documentMode

    if (!this.isIE11) {
      lax.setup({ breakpoints: { tosmall: 0, atsmall: 768 } })
      lax.addElement(this.richText)
      if (this.image) {
        lax.addElement(this.image)
      }
      document.addEventListener('scroll', this.laxScrollHandler)
    }

    if (this.image) objectFitImages(this.image)
  }

  beforeDestroy() {
    if (!this.isIE11) {
      document.removeEventListener('scroll', this.laxScrollHandler)
    }
  }
}
