




import { Vue, Component, Prop } from 'vue-property-decorator'

import UiArticleGrid from 'mtd-ui/src/components/UiArticleGrid/UiArticleGrid.vue'
import { PropsData as UiArticleGridPropsData } from 'mtd-ui/src/components/UiArticleGrid/UiArticleGrid.types'
import { PropData } from './NewsList.types'

@Component({ components: { UiArticleGrid } })
export default class NewsList extends Vue {
  @Prop() data!: PropData

  get propsData(): UiArticleGridPropsData {
    const articles = [
      ...this.data.list.map(item => {
        let image

        if (item.media.length) {
          image = {
            url: item.media[0].images.listViewImage.publicUrl,
            alt: item.title,
            width: item.media[0].images.listViewImage.dimensions.width,
            height: item.media[0].images.listViewImage.dimensions.height
          }
        }

        return {
          title: item.title,
          date: item.datetime,
          link: item.slug,
          image
        }
      })
    ]

    return {
      articles
    }
  }
}
