







import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropData } from './CeNews_pi1.types'
import NewsDetail from './NewsDetail/NewsDetail.vue'
import NewsList from './NewsList/NewsList.vue'

@Component({
  components: {
    NewsDetail,
    NewsList
  }
})
export default class CeNews_pi1 extends Vue {
  @Prop() data!: PropData

  get isList() {
    return this.data.settings.action === 'list'
  }

  get isDetail() {
    return this.data.settings.action === 'detail'
  }
}
