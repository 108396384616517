




import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropItems, PropI18n } from './CeMtdbase_videoslider.types'
import UiVideos from 'mtd-ui/src/components/UiVideos/UiVideos.vue'
import { PropsData as UiVideosPropsData } from 'mtd-ui/src/components/UiVideos/UiVideos.types'

@Component({ components: { UiVideos } })
export default class CeMtdbase_videoslider extends Vue {
  @Prop() items!: PropItems
  @Prop() i18n!: PropI18n

  get uiVideosPropsData(): UiVideosPropsData {
    const videos = this.items.map(item => {
      let ytId

      if (!item.content.video) {
        ytId = ''
      } else {
        ytId = item.content.video.publicUrl.match(/embed\/(.*)\?/)
      }

      return {
        youtubeId: ytId && ytId.length === 2 ? ytId[1] : '',
        title: item.content.title
      }
    })

    return {
      videos,
      i18n: {
        next: this.i18n.nextBtn,
        prev: this.i18n.prevBtn
      }
    }
  }
}
