




import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropBackground, PropAppearance } from './CeMtdbase_herorte.types'
import UiHero from 'mtd-ui/src/components/UiHero/UiHero.vue'
import { PropsData as UiHeroPropsData } from 'mtd-ui/src/components/UiHero/UiHero.types'
import { Breadcrumbs } from '../../store/initialData.types'

@Component({ components: { UiHero } })
export default class CeMtdbase_herorte extends Vue {
  @Prop() bodytext!: string
  @Prop() background!: PropBackground
  @Prop() appearance!: PropAppearance

  get breadcrumbs(): Breadcrumbs {
    if ((this as any).$store.state.typo3.page.page) {
      return (this as any).$store.state.typo3.page.page.breadcrumbs
    } else {
      return [] // In case of 404 page, where breadcrumbs are not available
    }
  }

  get uiHeroPropsData(): UiHeroPropsData {
    let image
    let breadcrumbs

    if (this.background) {
      const imageAlt = this.background.properties.alternative

      image = {
        url: this.background.publicUrl,
        urlRetina: this.background.urlRetina,
        urlLqip: this.background.urlLqip,
        alt: imageAlt ? imageAlt : '',
        width: this.background.properties.dimensions.width,
        height: this.background.properties.dimensions.height
      }
    }

    if (this.breadcrumbs.length) {
      breadcrumbs = this.breadcrumbs.map(page => {
        return {
          title: page.title,
          link: page.link
        }
      })
    }

    return {
      richText: this.bodytext,
      image,
      breadcrumbs,
      is404: this.appearance.layout === '404' ? true : undefined
    }
  }
}
