








import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropData } from './NewsDetail.types'
import UiBlogPost from 'mtd-ui/src/components/UiBlogPost/UiBlogPost.vue'
import { PropsData as UiBlogPostPropsData } from 'mtd-ui/src/components/UiBlogPost/UiBlogPost.types'

@Component({ components: { UiBlogPost } })
export default class NewsDetail extends Vue {
  @Prop() data!: PropData

  get uiBlogPostPropsData(): UiBlogPostPropsData {
    let image

    if (this.data.detail.media[0].images.detailViewImage.publicUrl) {
      image = {
        url: this.data.detail.media[0].images.detailViewImage.publicUrl,
        urlRetina: this.data.detail.media[0].images.detailViewImage.publicUrl,
        urlLqip: this.data.detail.media[0].images.detailViewImage.publicUrl,
        alt: this.data.detail.title,
        width: this.data.detail.media[0].images.detailViewImage.dimensions
          .width,
        height: this.data.detail.media[0].images.detailViewImage.dimensions
          .height
      }
    }

    return {
      title: this.data.detail.title,
      date: this.data.detail.datetime,
      image,
      richText: this.data.detail.bodytext
    }
  }
}
