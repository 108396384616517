




import { Vue, Component, Prop } from 'vue-property-decorator'

import {
  PropHeader,
  PropDescription,
  PropCover,
  PropFile,
  PropDownloadLabel
} from './CeMtdbase_downloadfile.types'
import UiFile from 'mtd-ui/src/components/UiFile/UiFile.vue'
import { PropsData as UiFilePropsData } from 'mtd-ui/src/components/UiFile/UiFile.types'

@Component({ components: { UiFile } })
export default class CeMtdbase_downloadfile extends Vue {
  @Prop() header!: PropHeader
  @Prop() description!: PropDescription
  @Prop() cover!: PropCover
  @Prop() file!: PropFile
  @Prop() downloadLabel!: PropDownloadLabel

  get uiFilePropsData(): UiFilePropsData {
    let cover

    if (this.cover) {
      cover = {
        url: this.cover.publicUrl,
        width: this.cover.properties.dimensions.width,
        height: this.cover.properties.dimensions.height
      }
    }

    return {
      title: this.header,
      description: this.description,
      cover,
      fileUrl: this.file.publicUrl,
      extension: this.file.properties.extension,
      size: this.file.properties.size,
      i18n: {
        download: this.downloadLabel
      }
    }
  }
}
