var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.onVisibilityChanged,
      throttle: 100,
      intersection: { threshold: 0.2 }
    }),expression:"{\n      callback: onVisibilityChanged,\n      throttle: 100,\n      intersection: { threshold: 0.2 }\n    }"}],ref:"videos",class:_vm.$style.videos},[_c('div',{class:_vm.$style.videosOuter},[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.propsData.videos),function(video,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentVideoIndex === index),expression:"currentVideoIndex === index"}],key:((video.youtubeId) + "-" + index),class:_vm.$style.videosItem},[_c('img',{class:_vm.$style.videosItemImage,attrs:{"src":("https://i3.ytimg.com/vi/" + (video.youtubeId) + "/maxresdefault.jpg"),"alt":video.title}}),_vm._v(" "),_c('div',{class:_vm.$style.videosItemTitle},[_vm._v(_vm._s(video.title))]),_vm._v(" "),_c('div',{class:_vm.$style.videosItemPlayButton,on:{"click":_vm.toggleOverlayVisibility}},[_c('div',{attrs:{"role":"button","title":"Play Video"}},[_c('svg-play')],1)])])}),0)],1),_vm._v(" "),(_vm.propsData.videos.length > 1)?_c('div',{class:_vm.$style.videosNavigation},[_c('div',{class:[
          _vm.$style.videosNavigationButton,
          _vm.$style.videosNavigationButton_PREV
        ],attrs:{"role":"button"},on:{"click":function($event){return _vm.prevVideo()}}},[_c('svg-arrow-2'),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.propsData.i18n.prev))])],1),_vm._v(" "),_c('div',{class:[
          _vm.$style.videosNavigationButton,
          _vm.$style.videosNavigationButton_NEXT
        ],attrs:{"role":"button"},on:{"click":function($event){return _vm.nextVideo()}}},[_c('div',{class:_vm.$style.videosNavigationButtonNextPreview},[_c('div',{class:_vm.$style.videosNavigationButtonNextPreviewImage},[_c('img',{ref:"image",attrs:{"src":("https://i3.ytimg.com/vi/" + (_vm.propsData.videos[_vm.nextVideoIndex].youtubeId) + "/mqdefault.jpg"),"alt":_vm.propsData.videos[_vm.nextVideoIndex].title}})]),_vm._v(" "),_c('div',{class:_vm.$style.videosNavigationButtonNextPreviewTitle},[_vm._v("\n            "+_vm._s(_vm.propsData.videos[_vm.nextVideoIndex].title)+"\n          ")])]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.propsData.i18n.next))]),_vm._v(" "),_c('svg-arrow-2')],1)]):_vm._e()]),_vm._v(" "),_c('div',{class:[_vm.$style.overlay, ( _obj = {}, _obj[_vm.$style.overlay_VISIBLE] = _vm.isOverlayVisible, _obj )],on:{"click":function($event){return _vm.toggleOverlayVisibility($event)}}},[(_vm.isOverlayVisible)?_c('div',{class:_vm.$style.overlayInner},[_c('div',{class:_vm.$style.overlayInnerClose,attrs:{"role":"button","title":"Close Overlay"},on:{"click":function($event){return _vm.toggleOverlayVisibility($event)}}},[_c('svg-cross-mark')],1),_vm._v(" "),_c('div',{class:_vm.$style.overlayInnerVideo},[(_vm.isIframeVisible)?_c('iframe',{attrs:{"src":'https://www.youtube-nocookie.com/embed/' +
              _vm.propsData.videos[_vm.currentVideoIndex].youtubeId +
              '?autoplay=1',"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"}}):_vm._e()])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }