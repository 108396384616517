// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ZSZn{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;margin-bottom:-2rem}@media(min-width:768px){._1ZSZn{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;margin-right:-4rem;margin-bottom:-4rem}}@media(min-width:990px){._1ZSZn{margin-bottom:-5rem}}@media(min-width:768px){._1ZSZn>div{margin-right:4rem}}._1om7w{margin-right:0;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}._1om7w>div{margin-right:0}._1om7w ._1O0xe{text-align:center}._1O0xe{font-size:2.5rem;font-family:\"Roboto Condensed\",sans-serif;text-transform:uppercase;margin:0}@media(min-width:768px){._1O0xe{font-size:2.8rem}}@media(min-width:990px){._1O0xe{font-size:3.2rem}}@media(min-width:1280px){._1O0xe{font-size:4rem}}@media(max-width:767px){._1O0xe{text-align:center}}@media(min-width:768px){._1O0xe{max-width:40rem}}@media(min-width:990px){._1O0xe{max-width:45rem}}@media(min-width:1280px){._1O0xe{max-width:60rem}}@media(max-width:767px){._3bWrp{margin-top:1.5rem}}", ""]);
// Exports
exports.locals = {
	"content": "_1ZSZn",
	"content_NO_BUTTON": "_1om7w",
	"title": "_1O0xe",
	"button": "_3bWrp"
};
module.exports = exports;
