




import { Vue, Component, Prop } from 'vue-property-decorator'

import UiContactinfo from 'mtd-ui/src/components/UiContactinfo/UiContactinfo.vue'
import { PropsData as UiContactinfoPropsData } from 'mtd-ui/src/components/UiContactinfo/UiContactinfo.types'

@Component({ components: { UiContactinfo } })
export default class CeMtdbase_contactinfo extends Vue {
  @Prop() bodytext!: string
  @Prop() header!: string

  get uiContactinfoPropsData(): UiContactinfoPropsData {
    return {
      header: this.header,
      richText: this.bodytext
    }
  }
}
