


































































































































































































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import FuseTS from 'fuse.js'
import Fuse from 'fuse.js-ie11'
import Simplebar from 'simplebar'
import 'simplebar/dist/simplebar.css'

import { ApiResponse, ResultItem } from './UiHeaderSearch.types'
import SvgSearch from 'mtd-ui/src/icons/search.svg'
import SvgDocument from 'mtd-ui/src/icons/document.svg'
import SvgCrossMark from 'mtd-ui/src/icons/cross-mark.svg'

@Component({
  components: {
    SvgSearch,
    SvgDocument,
    SvgCrossMark
  }
})
export default class UiHeaderSearch extends Vue {
  @Prop({ required: true, type: String }) apiBaseUrl!: string
  @Prop({ required: true, type: String }) searchEndpoint!: string
  @Prop({ required: true, type: Boolean }) isSearchVisible!: boolean
  @Prop({ required: true, type: String }) translationLabel!: string
  @Prop({ required: true, type: String }) translationInputPlaceholder!: string
  @Prop({ required: true, type: String }) translationNoResultsFound!: string
  @Prop({ required: true, type: String }) translationClearResults!: string
  @Prop({ required: true, type: String }) translationShowMore!: string
  @Prop({ required: true, type: String }) translationPages!: string
  @Prop({ required: true, type: String }) translationProducts!: string
  @Prop({ required: true, type: String }) translationNews!: string

  fuse: FuseTS<ResultItem> | undefined = undefined
  isFuseInitialized = false
  query = ''
  results: FuseTS.FuseResult<ResultItem>[] = []
  resultsHavePage = false
  resultsHaveProduct = false
  resultsHaveNews = false
  isSearchInputFocused = false

  @Ref('searchInput') searchInput!: HTMLInputElement
  @Ref('resultsInner') resultsInner!: HTMLDivElement

  @Watch('results')
  onResultsChanged() {
    this.resultsHavePage = false
    this.resultsHaveProduct = false
    this.resultsHaveNews = false

    for (let i = 0; i < this.results.length; i++) {
      if (this.results[i].item.type === 'page') {
        this.resultsHavePage = true
        break
      }
    }

    for (let i = 0; i < this.results.length; i++) {
      if (this.results[i].item.type === 'product') {
        this.resultsHaveProduct = true
        break
      }
    }

    for (let i = 0; i < this.results.length; i++) {
      if (this.results[i].item.type === 'news') {
        this.resultsHaveNews = true
        break
      }
    }
  }

  @Watch('isSearchVisible')
  @Watch('isFuseInitialized')
  onFuseVisibilityChanged() {
    if (this.searchInput && this.isSearchVisible && this.isFuseInitialized) {
      this.query = ''
      this.$nextTick(() => {
        setTimeout(() => {
          this.searchInput.focus()
        }, 150)
      })
    }
  }

  parsedResultTitle(result: FuseTS.FuseResult<ResultItem>): string {
    let title = result.item.title
    if (typeof result.matches === 'undefined') return title

    for (let i = 0; i < result.matches.length; i++) {
      if (result.matches[i].key !== 'title') continue

      for (let j = 0; j < result.matches[i].indices.length; j++) {
        const rangeToHighlight = result.matches[i].indices[j]

        const titleArr = title.split('')

        titleArr.splice(rangeToHighlight[0], 0, '<span>')
        titleArr.splice(rangeToHighlight[1] + 2, 0, '</span>')

        title = titleArr.join('')
        break
      }

      break
    }

    return title
  }

  parsedResultDescription(result: FuseTS.FuseResult<ResultItem>): string {
    if (result.item.description === null) return ''

    let description = result.item.description

    if (typeof result.matches === 'undefined') return description

    for (let i = 0; i < result.matches.length; i++) {
      if (result.matches[i].key !== 'description') continue

      for (let j = 0; j < result.matches[i].indices.length; j++) {
        const highlightRange = result.matches[i].indices[j]

        if (highlightRange[1] - highlightRange[0] < this.query.length - 1) {
          continue
        }

        const descriptionArr = description.split('')

        descriptionArr.splice(highlightRange[0], 0, '<span>')
        descriptionArr.splice(highlightRange[1] + 2, 0, '</span>')

        description = descriptionArr.join('')
        break
      }

      break
    }

    return description.length > 80
      ? description.substr(0, 80 - 1) + '&hellip;'
      : description
  }

  @Watch('query')
  onQueryChanged() {
    if (typeof this.fuse === 'undefined') return

    this.results = []

    if (this.query.trim().length < 3) return

    this.results = this.fuse.search(this.query.trim())

    // @ts-ignore
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode
    if (!isIE11) this.resultsInner.scrollTo(0, 0)
  }

  async initFuse() {
    let apiBaseUrl = (this as any).$store.$typo3.api.options.baseURL
    apiBaseUrl = apiBaseUrl.substring(0, apiBaseUrl.length - 1)

    try {
      const response: ApiResponse = await fetch(
        this.searchEndpoint
      ).then(r => r.json())

      const searchDataset = response.content.colPos0[0].content.data.documents.list.results // prettier-ignore
      // @ts-ignore
      this.fuse = new Fuse(searchDataset, {
        ignoreLocation: true,
        ignoreFieldNorm: true,
        minMatchCharLength: 3,
        threshold: 0.3,
        includeScore: true,
        includeMatches: true,
        keys: ['title', 'content', 'description', 'productCategory']
      })
      this.isFuseInitialized = true
    } catch (err) {
      console.log(err)
    }
  }

  mounted() {
    this.initFuse()

    document.addEventListener('keydown', (e: any) => {
      if (this.isSearchInputFocused === false && e.keyCode === 27) {
        this.$emit('close-search')
      }
    })

    if ('ontouchstart' in window === false) {
      new Simplebar(this.resultsInner, {
        autoHide: false
      })
      this.resultsInner.style.paddingRight = '15px'
    } else {
      this.resultsInner.style.overflow = 'auto'
    }
  }
}
