




import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropQuestions } from './CeMtdbase_faq.types'
import UiAccordion from 'mtd-ui/src/components/UiAccordion/UiAccordion.vue'
import { PropsData as UiAccordionPropsData } from 'mtd-ui/src/components/UiAccordion/UiAccordion.types'

@Component({ components: { UiAccordion } })
export default class CeMtdbase_faq extends Vue {
  @Prop() questions!: PropQuestions

  get uiAccordionPropsData(): UiAccordionPropsData {
    const accordions = this.questions.map(question => {
      let nestedAccordions

      if (question.content.subquestions.length) {
        nestedAccordions = [
          ...question.content.subquestions.map(subquestion => {
            return {
              label: subquestion.content.question,
              richText: subquestion.content.answer
            }
          })
        ]
      }

      return {
        label: question.content.question,
        richText: question.content.answer,
        nestedAccordions
      }
    })

    return {
      accordions
    }
  }
}
