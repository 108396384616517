




import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropLink, PropForm } from './CeForm_formframework.types'
import UiForm from 'mtd-ui/src/components/UiForm/UiForm.vue'
import { PropsData as UiFormPropsData } from 'mtd-ui/src/components/UiForm/UiForm.types'
import { SitesMenu } from '../../store/initialData.types'

@Component({ components: { UiForm } })
export default class CeForm_formframework extends Vue {
  @Prop() link!: PropLink
  @Prop() form!: PropForm
  @Prop() header!: string

  get sitesMenu(): SitesMenu {
    return (this as any).$store.state.typo3.initial.sitesMenu
  }

  get uiFormPropsData(): UiFormPropsData {
    const inputElements = this.form.elements.map(element => {
      let radioOptions

      if (
        element.type === 'RadioButton' &&
        typeof element.properties !== 'undefined'
      ) {
        radioOptions = element.properties.options
      }

      let countryList

      if (
        element.type === 'SingleSelect' &&
        typeof element.properties !== 'undefined'
      ) {
        countryList = element.properties.options
      }

      let optionalRichText

      if (element.properties && element.properties.agreementText) {
        optionalRichText = element.properties.agreementText
      } else if (element.properties && element.properties.elementDescription) {
        optionalRichText = element.properties.elementDescription
      }

      return {
        type: element.type,
        label: element.label,
        identifier: element.identifier,
        name: element.name,
        radioOptions,
        validators: element.validators,
        countryList,
        defaultValue:
          element.defaultValue !== null ? element.defaultValue : undefined,
        optionalHeader:
          element.properties && element.properties.agreementHeader
            ? element.properties.agreementHeader
            : undefined,
        optionalRichText
      }
    })

    let defaultCountryCode: string = ''

    for (let i = 0; i < this.sitesMenu.length; i++) {
      if (this.sitesMenu[i].active) {
        defaultCountryCode = this.sitesMenu[i].countryLabel
        break
      }
    }

    return {
      heading: this.header,
      inputElements,
      defaultCountryCode,
      actionUrl: this.link.url,
      i18n: {
        submit: this.form.i18n.submitButton,
        requiredField: this.form.i18n.requiredFields,
        sending: this.form.i18n.sendingLabel,
        errorsOccured: this.form.i18n.validationErrors,
        serverError: this.form.i18n.serverError,
        selectFile: this.form.i18n.selectFileLabel,
        removeSelectedFile: this.form.i18n.removeSelectedFileButton
      }
    }
  }
}
