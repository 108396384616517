




import { Vue, Component, Prop } from 'vue-property-decorator'

import UiText from 'mtd-ui/src/components/UiText/UiText.vue'
import { PropsData as UiTextPropsData } from 'mtd-ui/src/components/UiText/UiText.types'

@Component({ components: { UiText } })
export default class CeText extends Vue {
  @Prop() bodytext!: string

  get uiImagePropsData(): UiTextPropsData {
    return {
      richText: this.bodytext
    }
  }
}
