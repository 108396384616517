




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class BaseRichText extends Vue {
  @Prop({ required: true }) richText!: string
}
