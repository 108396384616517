










































import { Component, Vue, Prop } from 'vue-property-decorator'

import SvgCheckMark from 'mtd-ui/src/icons/check-mark.svg'

@Component({
  components: {
    SvgCheckMark
  }
})
export default class UiProductListingOption extends Vue {
  @Prop({ required: true }) type!: 'radio' | 'checkbox'
  @Prop({ required: true }) label!: string
  @Prop({ required: true }) isInactive!: boolean
  @Prop({ required: true }) isChecked!: boolean
  @Prop({ required: true }) amount!: number
}
