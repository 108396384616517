var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.pagination},[_c('a',{class:[
      _vm.$style.paginationArrow,
      _vm.$style.paginationArrow_PREV,
      ( _obj = {}, _obj[_vm.$style.paginationArrow_DISABLED] = _vm.currentPage === 1, _obj )
    ],attrs:{"href":_vm.prevPageLink,"title":"Previous Page"},on:{"click":function($event){$event.preventDefault();return (function () {
        _vm.prevHandler()
        _vm.scrollTopHandler()
      })($event)}}},[_c('svg-arrow')],1),_vm._v(" "),_c('div',{class:_vm.$style.paginationCurrent},[_c('span',[_vm._v("\n      "+_vm._s(_vm.currentPage)+"\n    ")])]),_vm._v(" "),_c('div',{class:_vm.$style.paginationText},[_c('span',[_vm._v("/")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.pagesAmount))])]),_vm._v(" "),_c('a',{class:[
      _vm.$style.paginationArrow,
      _vm.$style.paginationArrow_NEXT,
      ( _obj$1 = {}, _obj$1[_vm.$style.paginationArrow_DISABLED] = _vm.currentPage === _vm.pagesAmount, _obj$1 )
    ],attrs:{"href":_vm.nextPageLink,"title":"Next Page"},on:{"click":function($event){$event.preventDefault();return (function () {
        _vm.nextHandler()
        _vm.scrollTopHandler()
      })($event)}}},[_c('svg-arrow')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }