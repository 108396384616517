




































































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import SvgFacebook from 'mtd-ui/src/icons/facebook.svg'
import SvgLinkedin from 'mtd-ui/src/icons/linkedin.svg'
import SvgMail from 'mtd-ui/src/icons/mail.svg'
import SvgTwitter from 'mtd-ui/src/icons/twitter.svg'

const objectFitImages = require('object-fit-images')

import { PropsData } from './UiProductDetails.types'

@Component({
  components: {
    SvgFacebook,
    SvgLinkedin,
    SvgMail,
    SvgTwitter
  }
})
export default class UiProductDetails extends Vue {

  fullUrl = ''

  @Prop({ required: true }) propsData!: PropsData
  @Ref('image') image!: HTMLImageElement

  mounted() {
    if (this.image) objectFitImages(this.image)
    this.fullUrl = window.location.href
  }
}
