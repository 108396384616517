var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.propsData.rows),function(row,index){
var _obj, _obj$1, _obj$2, _obj$3, _obj$4;
return _c('div',{key:index,class:[_vm.$style.row, ( _obj = {}, _obj[_vm.$style.row_NO_IMAGE] = !row.image, _obj )]},[(row.image)?_c('div',{class:[
        _vm.$style.rowImage,
        ( _obj$1 = {}, _obj$1[_vm.$style.rowImage_ODD] = parseInt(index.toString()) % 2, _obj$1 )
      ]},[_c('img',{ref:"image",refInFor:true,staticClass:"lazyload",attrs:{"src":row.image.urlLqip,"data-srcset":((row.image.url) + " 1x, " + (row.image.urlRetina) + " 2x"),"data-src":row.image.url,"alt":row.image.alt,"width":row.image.width,"height":row.image.height,"crossorigin":"anonymous"}})]):_vm._e(),_vm._v(" "),_c('div',{class:[_vm.$style.rowText, ( _obj$2 = {}, _obj$2[_vm.$style.rowText_NO_IMAGE] = !row.image, _obj$2 )]},[_c('div',{class:[
          _vm.$style.rowTextInner,
          ( _obj$3 = {}, _obj$3[_vm.$style.rowTextInner_ODD] = parseInt(index.toString()) % 2, _obj$3 ),
          ( _obj$4 = {}, _obj$4[_vm.$style.rowTextInner_NO_IMAGE] = !row.image, _obj$4 )
        ]},[_c('base-rich-text',{attrs:{"rich-text":row.richText}})],1)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }