<template>
  <ErrorBoundary :on-error="handleError">
    <LayoutLeftSidebar>
      <ce-renderer :content="content.colPos2" :frame="false" />

      <template v-slot:top>
        <ce-renderer :content="content.colPos0" :frame="false" />
      </template>
      <template v-slot:sidebar>
        <ce-renderer :content="content.colPos1" :frame="false" />
      </template>
    </LayoutLeftSidebar>
  </ErrorBoundary>
</template>

<script>
import baseBe from '~typo3/layouts/backend/BeDefault'
import ErrorBoundary from '../../components/utilities/ErrorBoundary'
import LayoutLeftSidebar from 'mtd-ui/src/components/LayoutLeftSidebar/LayoutLeftSidebar.vue'

export default {
  components: { LayoutLeftSidebar, ErrorBoundary },
  extends: baseBe,
  methods: {
    handleError (err) {
      if (this.$sentry) {
        this.$sentry.captureException(err)
      }
    }
  },
}
</script>
