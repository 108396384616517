







import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropData } from './CeMtdproducts_categorypage.types'
import UiProductListing from 'mtd-ui/src/components/UiProductListing/UiProductListing.vue'
import { PropsData as UiProductListingPropsData } from 'mtd-ui/src/components/UiProductListing/UiProductListing.types'
import { Configuration } from '../../store/initialData.types'

@Component({ components: { UiProductListing } })
export default class CeMtdproducts_categorypage extends Vue {
  @Prop() data!: PropData

  get configuration(): Configuration {
    return (this as any).$store.state.typo3.initial.configuration
  }

  get uiProductListingPropsData(): UiProductListingPropsData {
    const products = this.data.products.map(product => {
      let image

      if (product.image) {
        const imageAlt = product.image.properties.alternative

        image = {
          url: product.image.publicUrl,
          urlRetina: product.image.urlRetina,
          urlLqip: product.image.urlLqip,
          alt: imageAlt ? imageAlt : product.label,
          width: product.image.properties.dimensions.width,
          height: product.image.properties.dimensions.height
        }
      }

      return {
        title: product.label,
        link: product.url,
        price: product.price,
        priceFormatted: product.priceFormatted,
        categoryId: product.categoryId,
        description: product.description,
        attributes: product.attributes,
        image
      }
    })

    return {
      products,
      categories: this.data.categories,
      filters: this.data.filters,
      i18n: {
        all: this.data.i18n.all,
        clearFilters: this.data.i18n.clearFilters,
        filters: this.data.i18n.filters,
        learnMore: this.data.i18n.learnMore,
        subcategories: this.data.i18n.subcategories,
        subcategoriesAndFilters: this.data.i18n.subcategoriesFilters,
        perPage: this.data.i18n.perPage,
        sorting: {
          label: this.data.i18n.sorting.label,
          default: this.data.i18n.sorting.default,
          priceLowToHigh: this.data.i18n.sorting.priceLowToHigh,
          priceHighToLow: this.data.i18n.sorting.priceHighToLow
        }
      },
      arePricesVisible: this.configuration.showPrices
    }
  }
}
