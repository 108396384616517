import Vue from 'vue'
import { registerBackendLayouts } from '~typo3/plugins/layouts'
import BeDefault from '~typo3/layouts/backend/BeDefault'

import BeContentWithLeftSidebar from '../layouts/backend/BeContentWithLeftSidebar.vue'

const layouts = {
  BeDefault,
  BeContentWithLeftSidebar
}

export default ({ app }) => {
  Vue.use(registerBackendLayouts, {
    context: app,
    layouts
  })
}
