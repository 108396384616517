// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media(min-width:768px){._3-6cw{min-height:49rem;max-height:74rem;height:calc(100vh - 21rem);position:relative}._3-6cw>div{position:absolute;width:100%}}._3TIUj{display:flex;justify-content:center}@media(max-width:767px){._3TIUj{margin-top:15rem;margin-bottom:14rem}}@media(min-width:768px){._3TIUj{margin-top:1.5rem}}", ""]);
// Exports
exports.locals = {
	"placeholderArea": "_3-6cw",
	"placeholderAreaSpinner": "_3TIUj"
};
module.exports = exports;
