// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1BpZv{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-1.5rem;margin-bottom:-3rem}@media(min-width:768px){._1BpZv{margin-right:-4rem;margin-bottom:-4rem}}._7Gj2T{color:var(--color-black);margin-right:1.5rem;margin-bottom:3rem;width:calc(50% - 1.5rem)}@media(min-width:768px){._7Gj2T{margin-right:4rem;margin-bottom:4rem;width:calc(50% - 4rem)}}@media(min-width:990px){._7Gj2T{width:calc(33.33% - 4rem)}}._7Gj2T:hover .cTIvU{color:#d50c2f}._7Gj2T:hover img{opacity:.85}._3fVgP{height:30vw;position:relative;margin-bottom:1rem;overflow:hidden;-webkit-transition:inherit;transition:inherit;background:#f5f5f5}@media(min-width:768px){._3fVgP{margin-bottom:2rem;height:28vw}}@media(min-width:990px){._3fVgP{height:19vw}}@media(min-width:1280px){._3fVgP{height:24rem}}._3fVgP img{width:100%;height:100%;-o-object-fit:cover;object-fit:cover;-webkit-transition:inherit;transition:inherit;font-family:\"object-fit: cover;\"}.W4KHB{position:absolute;bottom:0;left:0;background:#fff;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-weight:700;font-size:1rem;padding:.7rem 1.5rem}@media(min-width:768px){.W4KHB{font-size:1.2rem;padding:1rem 2rem}}.W4KHB svg{fill:#111;margin-right:.8rem;height:1.6rem}@media(min-width:768px){.W4KHB svg{margin-right:1rem;height:2rem}}.cTIvU{font-size:1.6rem;font-family:\"Roboto Condensed\",sans-serif;-webkit-transition:inherit;transition:inherit}@media(min-width:768px){.cTIvU{font-size:2rem}}@media(min-width:990px){.cTIvU{font-size:2.2rem}}@media(min-width:1280px){.cTIvU{font-size:2.5rem}}", ""]);
// Exports
exports.locals = {
	"articles": "_1BpZv",
	"article": "_7Gj2T",
	"articleTitle": "cTIvU",
	"articleImage": "_3fVgP",
	"articleImageDate": "W4KHB"
};
module.exports = exports;
