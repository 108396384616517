




import { Vue, Component, Prop } from 'vue-property-decorator'

import { PropItems } from './CeMtdbase_timeline.types'
import UiTimeline from 'mtd-ui/src/components/UiTimeline/UiTimeline.vue'
import { PropsData as UiTimelinePropsData } from 'mtd-ui/src/components/UiTimeline/UiTimeline.types'

@Component({ components: { UiTimeline } })
export default class CeMtdbase_timeline extends Vue {
  @Prop() items!: PropItems

  get uiTimelinePropsData(): UiTimelinePropsData {
    const years = this.items.map(item => {
      let image

      if (item.content.image) {
        const imageAlt = item.content.image.properties.alternative

        image = {
          url: item.content.image.publicUrl,
          urlRetina: item.content.image.urlRetina,
          urlLqip: item.content.image.urlLqip,
          alt: imageAlt
            ? imageAlt
            : item.content.bodytext
                .replace(/(<([^>]+)>)/gi, '')
                .substring(0, 100),
          width: item.content.image.properties.dimensions.width,
          height: item.content.image.properties.dimensions.height
        }
      }

      return {
        year: item.content.year,
        richText: item.content.bodytext,
        image
      }
    })

    return {
      years
    }
  }
}
