















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class BaseSpinner extends Vue {
  @Prop({ type: Boolean, default: false }) isLarge!: boolean
}
