






import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Error extends Vue {
  contentElements: any[] = []

  created() {
    ;(this as any).$typo3.api.getPage('404').then((r: any) => {
      this.contentElements = r.data.content.colPos0
    })
  }
}
