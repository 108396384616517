




























































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import gsap from 'gsap'

import { PropsData } from './UiFooter.types'
import SvgArrow2 from 'mtd-ui/src/icons/arrow-2.svg'
import SvgExternalLink from 'mtd-ui/src/icons/external-link.svg'

@Component({
  components: {
    SvgArrow2,
    SvgExternalLink
  }
})
export default class UiFooter extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  isGsapAnimationEnabled = true

  @Ref('info') info!: HTMLDivElement
  @Ref('menuItemInner') menuItemInner!: HTMLDivElement

  onVisibilityChanged(isVisible: boolean): void {
    if (!this.isGsapAnimationEnabled || window.innerWidth < 768) return

    const tl = gsap.timeline({
      defaults: { ease: 'power3.inOut', duration: 0.8 }
    })

    if (!isVisible) {
      tl.to(this.info, { opacity: 0, x: '1.5rem' })
      tl.to(this.menuItemInner, { opacity: 0 }, 0)
    } else {
      tl.to(this.info, { opacity: 1, x: 0 })
      tl.to(
        this.menuItemInner,
        {
          opacity: 1,
          stagger: { each: 0.02 }
        },
        0
      )
      this.isGsapAnimationEnabled = false
    }
  }

  scrollTopHandler(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}
