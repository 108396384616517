// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._4NXkm{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}@media(min-width:768px){._4NXkm{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;margin-right:-4rem}}@media(min-width:768px){._4NXkm>div{margin-right:4rem}}._2FulX{-webkit-box-flex:1;-ms-flex:1;flex:1}@media(max-width:767px){._2FulX{margin-top:4rem}}.cI1Lj{-webkit-box-flex:2;-ms-flex:2;flex:2}@media(max-width:767px){.cI1Lj{-webkit-box-ordinal-group:0;-ms-flex-order:-1;order:-1}}", ""]);
// Exports
exports.locals = {
	"container": "_4NXkm",
	"sidebar": "_2FulX",
	"content": "cI1Lj"
};
module.exports = exports;
