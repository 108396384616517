











































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
const objectFitImages = require('object-fit-images')

import { PropsData } from './UiCategoryGrid.types'
import BaseLearnMore from '../BaseLearnMore/BaseLearnMore.vue'

@Component({ components: { BaseLearnMore } })
export default class UiCategoryGrid extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  @Ref('image') image!: HTMLImageElement

  mounted() {
    if (this.image) objectFitImages(this.image)
  }
}
