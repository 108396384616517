// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1nlD4{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:1rem}._1nlD4:last-child{margin-bottom:0}._1nlD4:hover ._8r4al,._1nlD4:hover ._3gDxR{border:.2rem solid #d50c2f}._2Cg6_{opacity:.2;pointer-events:none}._8r4al{width:1.8rem;height:1.8rem;border:.2rem solid #111;border-radius:.2rem;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin-right:.6rem;-ms-flex-negative:0;flex-shrink:0}@media(min-width:768px){._8r4al{width:2rem;height:2rem;margin-right:.8rem}}._8r4al svg{width:1.1rem;fill:transparent}@media(min-width:768px){._8r4al svg{width:1.2rem}}.-vNWf{background:#d50c2f;border:.2rem solid #d50c2f}.-vNWf svg{fill:#fff}._3gDxR{width:1.8rem;height:1.8rem;border:.2rem solid #111;border-radius:50%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin-right:.6rem;-ms-flex-negative:0;flex-shrink:0}@media(min-width:768px){._3gDxR{width:2rem;height:2rem;margin-right:.8rem}}._3gDxR div{width:.6rem;height:.6rem;background:#fff;border-radius:50%}._2uyvB{background:#d50c2f;border:.2rem solid #d50c2f}._1k4Am{font-size:1.1rem;padding:0 .7rem;border:.1rem solid #e8e8e8;margin-left:1rem;border-radius:1.5rem}@media(min-width:768px){._1k4Am{font-size:1.3rem}}._1k4Am span{opacity:.65}", ""]);
// Exports
exports.locals = {
	"option": "_1nlD4",
	"optionCheckbox": "_8r4al",
	"optionRadio": "_3gDxR",
	"option_INACTIVE": "_2Cg6_",
	"optionCheckbox_ACTIVE": "-vNWf",
	"optionRadio_ACTIVE": "_2uyvB",
	"optionAmount": "_1k4Am"
};
module.exports = exports;
