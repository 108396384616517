// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3baC_{background:#f5f5f5;display:-webkit-box;display:-ms-flexbox;display:flex;padding:1.5rem}._3baC_:hover ._2iVoX{color:#d50c2f}._2dMEl{margin-right:1.5rem;width:15rem}._2dMEl img{width:100%;height:100%;-o-object-fit:contain;object-fit:contain}._2B3yq{margin-right:1rem;-ms-flex-negative:0;flex-shrink:0}@media(min-width:768px){._2B3yq{margin-right:1.5rem}}._2B3yq svg{width:3.5rem;height:3.5rem;fill:#111}@media(min-width:768px){._2B3yq svg{width:5.5rem;height:5.5rem}}._3L8_7{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-flex:1;-ms-flex:1;flex:1}._3L8_7,._3MrUM{-webkit-transition:inherit;transition:inherit}._3MrUM>div{margin-bottom:1rem}._1ut_Y{text-transform:uppercase;opacity:.8;font-size:1.2rem}@media(min-width:768px){._1ut_Y{font-size:1.4rem}}._2iVoX{font-size:2rem;font-family:\"Roboto Condensed\",sans-serif;-webkit-transition:inherit;transition:inherit;margin-top:-.2em}@media(min-width:768px){._2iVoX{font-size:2.2rem}}@media(min-width:990px){._2iVoX{font-size:2.6rem}}@media(min-width:1280px){._2iVoX{font-size:3rem}}.oTYBH{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;margin-top:1rem}", ""]);
// Exports
exports.locals = {
	"container": "_3baC_",
	"contentTextTitle": "_2iVoX",
	"cover": "_2dMEl",
	"icon": "_2B3yq",
	"content": "_3L8_7",
	"contentText": "_3MrUM",
	"contentSize": "_1ut_Y",
	"contentDownload": "oTYBH"
};
module.exports = exports;
