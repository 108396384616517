var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.$style.container]},[_c('div',{ref:"swiper",staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.propsData.slides),function(slide,index){
var _obj, _obj$1, _obj$2, _obj$3;
return _c('div',{key:index,staticClass:"swiper-slide",class:_vm.$style.slide},[_c('div',{class:[
            _vm.$style.slideImage,
            ( _obj = {}, _obj[_vm.$style.slideImage_NO_IMAGE] = !slide.image, _obj )
          ]},[(slide.image)?_c('img',{ref:"image",refInFor:true,staticClass:"lazyload",attrs:{"src":slide.image.urlLqip,"data-srcset":((slide.image.url) + " 1x, " + (slide.image.urlRetina) + " 2x"),"data-src":slide.image.url,"alt":slide.image.alt,"width":slide.image.width,"height":slide.image.height,"crossorigin":"anonymous"}}):_vm._e()]),_vm._v(" "),(slide.image)?_c('div',{class:_vm.$style.slideGradient}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"frame-container",class:_vm.$style.slideContentOuter},[_c('div',{class:[
              _vm.$style.slideContentInner,
              ( _obj$1 = {}, _obj$1[_vm.$style.slideContentInner_NO_IMAGE] = !slide.image, _obj$1 ),
              ( _obj$2 = {}, _obj$2[_vm.$style.slideContentInner_HAS_BARS] = _vm.propsData.slides.length > 1, _obj$2 )
            ]},[_c('div',{class:[
                _vm.$style.slideContentRichText,
                ( _obj$3 = {}, _obj$3[_vm.$style.slideContentRichText_IMAGE] = slide.image, _obj$3 )
              ]},[_c('base-rich-text',{attrs:{"rich-text":slide.richText}})],1)])])])}),0)]),_vm._v(" "),(_vm.propsData.slides.length > 1)?_c('div',{class:_vm.$style.barsOuter},[_c('div',{staticClass:"frame-container"},[_c('div',{class:_vm.$style.barsInner},_vm._l((new Array(_vm.propsData.slides.length)),function(_,index){
              var _obj, _obj$1, _obj$2, _obj$3;
return _c('div',{key:index,class:_vm.$style.bar,attrs:{"role":"button","title":"Change Active Slide"},on:{"click":function($event){return _vm.goTo(index)}}},[_c('div',{class:[
              _vm.$style.barInner,
              ( _obj = {}, _obj[_vm.$style.barInner_NO_IMAGE] = !_vm.propsData.slides[_vm.currentIndex]
                  .image, _obj )
            ]},[_c('div',{class:[
                _vm.$style.barInnerProgress,

                ( _obj$1 = {}, _obj$1[_vm.$style.barInnerProgress_ANIMATED] = _vm.progressAnimationHelper &&
                    _vm.isAutoplayEnabled &&
                    index === _vm.currentIndex, _obj$1 ),
                ( _obj$2 = {}, _obj$2[_vm.$style.barInnerProgress_SOLID] = !_vm.isAutoplayEnabled && index === _vm.currentIndex, _obj$2 ),

                ( _obj$3 = {}, _obj$3[_vm.$style.barInnerProgress_ALREADY_VIEWED] = _vm.isAutoplayEnabled && index < _vm.currentIndex, _obj$3 )
              ]})])])}),0)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }