// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2EqZs{width:100%;height:auto;vertical-align:middle}", ""]);
// Exports
exports.locals = {
	"image": "_2EqZs"
};
module.exports = exports;
