// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._27mv_{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-transition:inherit;transition:inherit}._7ANvO{text-transform:uppercase;font-family:\"Roboto Condensed\",sans-serif;margin-right:1rem;white-space:nowrap}._1Bubo{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:2.5rem;height:2.5rem;background:#e8e8e8;-webkit-transition:inherit;transition:inherit}@media(min-width:768px){._1Bubo{width:3rem;height:3rem}}._1Bubo svg{-webkit-transition:inherit;transition:inherit;width:.8rem;fill:#d50c2f}@media(min-width:768px){._1Bubo svg{width:1rem}}", ""]);
// Exports
exports.locals = {
	"container": "_27mv_",
	"containerText": "_7ANvO",
	"containerImage": "_1Bubo"
};
module.exports = exports;
