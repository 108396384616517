// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Qggf{font-style:normal;padding:.5rem 1.5rem}@media(min-width:768px){._2Qggf{padding:1rem 4rem}}._2Qggf:first-of-type{margin-top:2rem}.M8ZOy{font-size:1.8rem;font-family:\"Roboto Condensed\",sans-serif}@media(min-width:768px){.M8ZOy{font-size:2rem}}@media(min-width:990px){.M8ZOy{font-size:2.2rem}}@media(min-width:1280px){.M8ZOy{font-size:2.5rem}}", ""]);
// Exports
exports.locals = {
	"container": "_2Qggf",
	"header": "M8ZOy"
};
module.exports = exports;
