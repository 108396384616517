



















































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import gsap from 'gsap'
const objectFitImages = require('object-fit-images')

import { PropsData } from './UiProductGrid.types'
import BaseLearnMore from '../BaseLearnMore/BaseLearnMore.vue'

@Component({ components: { BaseLearnMore } })
export default class UiProductGrid extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  isGsapAnimationEnabled = true

  @Ref('image') image!: HTMLImageElement
  @Ref('component') component!: HTMLDivElement

  onVisibilityChanged(
    isVisible: boolean,
    entry: IntersectionObserverEntry
  ): void {
    if (!this.isGsapAnimationEnabled || window.innerWidth < 768) return

    const tl = gsap.timeline({
      defaults: { ease: 'power3.inOut', duration: 0.8 }
    })

    if (!isVisible) {
      if (entry.boundingClientRect.y < 0) return

      tl.to(this.component, { y: '5vh', opacity: 0 })
    } else {
      tl.to(this.component, { y: 0, opacity: 1 })
      this.isGsapAnimationEnabled = false
    }
  }

  mounted() {
    if (this.image) objectFitImages(this.image)
  }
}
