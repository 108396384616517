









import { Component, Vue, Prop } from 'vue-property-decorator'

import { PropAppearance, PropMenu, PropI18n } from './CeMenuSubpages.types'
import UiProductSlider from 'mtd-ui/src/components/UiProductSlider/UiProductSlider.vue'
import UiCategoryGrid from 'mtd-ui/src/components/UiCategoryGrid/UiCategoryGrid.vue'
import UiPageLinks from 'mtd-ui/src/components/UiPageLinks/UiPageLinks.vue'
import UiTitleBar from 'mtd-ui/src/components/UiTitleBar/UiTitleBar.vue'
import { PropsData as UiProductSliderPropsData } from 'mtd-ui/src/components/UiProductSlider/UiProductSlider.types'
import { PropsData as UiCategoryGridPropsData } from 'mtd-ui/src/components/UiCategoryGrid/UiCategoryGrid.types'
import { PropsData as UiPageLinksPropsData } from 'mtd-ui/src/components/UiPageLinks/UiPageLinks.types'
import { PropsData as UiTitleBarPropsData } from 'mtd-ui/src/components/UiTitleBar/UiTitleBar.types'

@Component({ components: { UiProductSlider, UiCategoryGrid, UiPageLinks, UiTitleBar } })
export default class CeMenu_subpages extends Vue {
  @Prop() appearance!: PropAppearance
  @Prop() menu!: PropMenu
  @Prop() i18n!: PropI18n
  @Prop() header!: string

  get componentName() {
    switch(this.appearance.layout) {
      case 'slider':
        return 'ui-product-slider'
      case 'productsPage':
        return 'ui-category-grid'
      case 'linksPage':
        return 'ui-page-links'
      default:
        return 'div'
    }
  }

  get propsData() {
    switch(this.componentName) {
      case 'ui-product-slider':
        return this.uiProductSliderPropsData
      case 'ui-category-grid':
        return this.uiCategoryGridPropsData
      case 'ui-page-links':
        return this.uiPageLinksPropsData
      default:
        return false
    }
  }

  get uiProductSliderPropsData(): UiProductSliderPropsData {
    const slides = this.menu.map(menuItem => {
      let image

      if (menuItem.image) {
        const imageAlt = menuItem.image.properties.alternative

        image = {
          url: menuItem.image.publicUrl,
          urlRetina: menuItem.image.urlRetina,
          urlLqip: menuItem.image.urlLqip,
          alt: imageAlt ? imageAlt : menuItem.title,
          width: menuItem.image.properties.dimensions.width,
          height: menuItem.image.properties.dimensions.height
        }
      }

      return {
        title: menuItem.title,
        link: menuItem.link,
        image
      }
    })
    return { slides }
  }

  get uiCategoryGridPropsData(): UiCategoryGridPropsData {
    const categories = this.menu.map(menuItem => {
      let image

      if (menuItem.image) {
        const imageAlt = menuItem.image.properties.alternative

        image = {
          url: menuItem.image.publicUrl,
          urlRetina: menuItem.image.urlRetina,
          urlLqip: menuItem.image.urlLqip,
          alt: imageAlt ? imageAlt : menuItem.title,
          width: menuItem.image.properties.dimensions.width,
          height: menuItem.image.properties.dimensions.height
        }
      }

      return {
        title: menuItem.title,
        richText: menuItem.description,
        link: menuItem.link,
        image
      }
    })

    return {
      categories,
      i18n: {
        learnMore: this.i18n.learnMore
      }
    }
  }

  get uiPageLinksPropsData(): UiPageLinksPropsData {
    const links = this.menu.map(({ title, description, link }) => {
      return {
        title,
        description,
        link
      }
    })

    return {
      links,
      i18n: {
        learnMore: this.i18n.learnMore
      }
    }
  }

  get uiTitleBarPropsData(): UiTitleBarPropsData {
    return {
      title: this.header
    }
  }
}
