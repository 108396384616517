// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".I2HZm{margin-bottom:1.5rem}@media(min-width:768px){.I2HZm{display:-webkit-box;display:-ms-flexbox;display:flex;min-height:28rem;margin-bottom:4rem}}.I2HZm:last-child{margin-bottom:0!important}@media(max-width:767px){.I2HZm:last-child ._2uFAZ{padding-bottom:0}}@media(max-width:767px){._2CGaC{margin-bottom:4rem}}.vw6Kh{-webkit-box-flex:1;-ms-flex:1;flex:1;position:relative}@media(max-width:767px){.vw6Kh{height:50vw}}.vw6Kh img{position:absolute;width:100%;height:100%;-o-object-fit:cover;object-fit:cover;vertical-align:top;font-family:\"object-fit: cover;\"}.eKoME{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}._3Wu7m{-webkit-box-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}._1u_2V{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;text-align:center}._2uFAZ{padding:2rem 0}@media(min-width:768px){._2uFAZ{padding:3rem 0 3rem 3rem}}@media(min-width:990px){._2uFAZ{padding:4rem 0 4rem 4rem}}@media(min-width:1280px){._2uFAZ{padding:5rem 0 5rem 5rem}}@media(min-width:768px){._1_eB4{padding:3rem 3rem 3rem 0}}@media(min-width:990px){._1_eB4{padding:4rem 4rem 4rem 0}}@media(min-width:1280px){._1_eB4{padding:5rem 5rem 5rem 0}}@media(min-width:768px){.jV6TB{padding:3rem 0}}@media(min-width:990px){.jV6TB{padding:4rem 0}}@media(min-width:1280px){.jV6TB{padding:5rem 0}}", ""]);
// Exports
exports.locals = {
	"row": "I2HZm",
	"rowTextInner": "_2uFAZ",
	"row_NO_IMAGE": "_2CGaC",
	"rowImage": "vw6Kh",
	"rowImage_ODD": "eKoME",
	"rowText": "_3Wu7m",
	"rowText_NO_IMAGE": "_1u_2V",
	"rowTextInner_ODD": "_1_eB4",
	"rowTextInner_NO_IMAGE": "jV6TB"
};
module.exports = exports;
