// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3qdNu{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:2.5rem}@media(min-width:768px){._3qdNu{margin-bottom:4rem}}._3qdNu:last-child{margin-bottom:0}._3qdNu:hover ._1-scH span:first-child{color:#d50c2f}._2SFcW{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-transition:inherit;transition:inherit}._3_iIr svg{width:3.5rem;height:3.5rem;fill:#111}@media(min-width:768px){._3_iIr svg{width:5.5rem;height:5.5rem}}._1-scH{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;margin:0 0 0 1.5rem;-webkit-transition:inherit;transition:inherit}@media(min-width:768px){._1-scH{margin:0 4rem 0 3rem}}@media(min-width:1280px){._1-scH{margin:0 4rem}}._1-scH span:first-child{-webkit-transition:inherit;transition:inherit;font-size:2.5rem;font-family:\"Roboto Condensed\",sans-serif;margin-bottom:.2em}@media(min-width:768px){._1-scH span:first-child{font-size:2.8rem}}@media(min-width:990px){._1-scH span:first-child{font-size:3.2rem}}@media(min-width:1280px){._1-scH span:first-child{font-size:4rem}}@media(max-width:767px){._30aek{display:none}}", ""]);
// Exports
exports.locals = {
	"link": "_3qdNu",
	"linkContentText": "_1-scH",
	"linkContent": "_2SFcW",
	"linkContentIcon": "_3_iIr",
	"linkLearnMore": "_30aek"
};
module.exports = exports;
