



























































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
const objectFitImages = require('object-fit-images')

import { PropsData } from './UiHero.types'
import SvgArrow from 'mtd-ui/src/icons/arrow.svg'

@Component({
  components: {
    SvgArrow
  }
})
export default class UiHero extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  isIE11 = false

  get mobileBreadcrumbIndex(): number | null {
    if (!this.propsData.breadcrumbs) return null

    if (this.propsData.breadcrumbs.length === 1) {
      return 0
    } else {
      return this.propsData.breadcrumbs.length - 2
    }
  }

  @Ref('container') container!: HTMLDivElement
  @Ref('image') image!: HTMLImageElement
  @Ref('richText') richText!: HTMLImageElement
  @Ref('breadcrumbs') breadcrumbs!: HTMLDivElement

  mounted() {
    objectFitImages(this.image)
  }
}
