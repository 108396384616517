








































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import gsap from 'gsap'
const objectFitImages = require('object-fit-images')

import { PropsData } from './UiArticleGrid.types'
import SvgCalendar from 'mtd-ui/src/icons/calendar.svg'

@Component({
  components: {
    SvgCalendar
  }
})
export default class UiArticleGrid extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  isGsapAnimationEnabled = true

  @Ref('articles') articles!: HTMLDivElement
  @Ref('image') image!: HTMLImageElement

  onVisibilityChanged(isVisible: boolean, entry: IntersectionObserverEntry) {
    if (!this.isGsapAnimationEnabled || window.innerWidth < 768) return

    const tl = gsap.timeline({
      defaults: { ease: 'power3.inOut', duration: 0.8 }
    })

    if (!isVisible) {
      if (entry.boundingClientRect.y < 0) return

      tl.to(this.articles, { y: '5vh', opacity: 0 })
    } else {
      tl.to(this.articles, { y: 0, opacity: 1 })
      this.isGsapAnimationEnabled = false
    }
  }

  mounted() {
    if (this.image) objectFitImages(this.image)
  }
}
